import React from 'react';
import {
  PiBatteryVerticalLowDuotone,
  PiBatteryVerticalEmptyDuotone,
  PiBatteryVerticalMediumDuotone,
  PiBatteryVerticalFullDuotone,
  PiBatteryVerticalHighDuotone,
  PiBatteryWarningVerticalDuotone,
  PiBatteryPlusVerticalDuotone,
} from 'react-icons/pi';

export default function GpsBatteryStatus({status}) {
  switch (status) {
    case 'extremely_low_battery':
      return <PiBatteryVerticalEmptyDuotone color="#FCFCFC" size={20}/>;
    case 'very_low_battery':
      return <PiBatteryVerticalLowDuotone color="#FCFCFC" size={20}/>;
    case 'low_battery':
      return <PiBatteryVerticalMediumDuotone color="#545454" size={20}/>;
    case 'medium':
      return <PiBatteryVerticalHighDuotone color="#FF9E01" size={20}/>;
    case 'high':
      return <PiBatteryVerticalFullDuotone color="#849E00" size={20}/>;
    case 'very_high':
      return <PiBatteryPlusVerticalDuotone color="#099F00" size={20}/>;
    case 'no_power':
      return <PiBatteryWarningVerticalDuotone color="#DBDBDB" size={20}/>;
    default:
      return null;
  }
}
