import {toast} from 'react-toastify';

import axios from './axios';
import {updateLoading} from './redux/slices/generalSlice';
import {updateSafeParkingStatus} from './redux/slices/deviceSlice';
import {get} from './helpers';

export async function enableOrDisableSafeParking(vehicleId, dispatch) {
  try {
    dispatch(updateLoading(true));
    const response = await axios.post('/vehicle/do-safe-park', {
      vehicleId,
    });
    dispatch(updateLoading(false));
    if (response?.success) {
      dispatch(updateSafeParkingStatus({
        imei: vehicleId,
        safe_parking_location: get(response, 'data.safe_parking_location', null),
      }));
      toast.success(response?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast.error(response?.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  } catch (e) {
    return false;
  }
}
