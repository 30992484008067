import axios from 'axios';

const _axios = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});
_axios.interceptors.request.use(
    async (config) => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
      } catch (e) {
        console.log(e);
      }
      return config;
    },
);
_axios.interceptors.response.use(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response.data;
    },
);
export default _axios;
