import React from 'react';
import DataTable from 'react-data-table-component';

import {categories} from '../../constants';
import {Chart} from 'react-google-charts';

export default function Transactions({transactions}) {

  const columns = [
    {
      name: 'Date',
      selector: row => new Date(row.date).toLocaleDateString(),
    },
    {
      name: 'Category',
      selector: row => {
        const category = categories.find((categoryDetails) => {
          return categoryDetails.name === row.category;
        });
        if (category) {
          return category.label;
        }
        return 'Other';
      },
    },
    {
      name: 'Amount',
      selector: row => {
        return (<strong className={`${row.type === 'income' ?
            'text-green-600':
            'text-red-600'}`}>
          {row.type === 'income' ? '+':'-'}{row.amount.toFixed(2)} Rs
        </strong>);
      },
    },
  ];

  let income = 0;
  let expense = 0;

  for (const transaction of transactions) {
    if (transaction.type === 'income') {
      income += transaction.amount;
    } else {
      expense += transaction.amount;
    }
  }

  const chartData = [
    ['Type', 'Amount'],
    ['Income', income],
    ['Expense', expense],
  ];

  return (
      <div>
        <div className="text-black text-xl font-bold leading-7 mb-5">
          Income / Expense
        </div>
        <div
            className="p-5 bg-white border border-neutral-100 shadow rounded-xl">
          {transactions.length > 0 && (
              <Chart
                  chartType="PieChart"
                  width="100%"
                  height="250px"
                  data={chartData}
                  options={{
                    legend: {position: 'bottom'},
                    is3D: true,
                  }}
              />
          )}
          <DataTable
              columns={columns}
              data={transactions}
              noDataComponent="No transactions found!"
          />
        </div>
      </div>
  );
}
