import React from 'react';
import {
  PiCellSignalMediumBold,
  PiCellSignalLowBold,
  PiCellSignalSlashBold,
  PiCellSignalHighBold,
  PiCellSignalFullBold
} from 'react-icons/pi';

export default function GpsSignalStrengthStatus({status}) {
  switch (status) {
    case 'extremely_weak_signal':
      return <PiCellSignalLowBold color="#FF9E01" size={20}/>;
    case 'very_weak_signal':
      return <PiCellSignalMediumBold color="#849E00" size={20}/>;
    case 'good_signal':
      return <PiCellSignalHighBold color="#099F00" size={20}/>;
    case 'strong_signal':
      return <PiCellSignalFullBold color="#099F00" size={20}/>;
    case 'no_signal':
      return <PiCellSignalSlashBold color="#DBDBDB" size={20}/>;
    default:
      return null;
  }
}
