import {useCallback, memo, useEffect, useRef} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import {getImageUrl, isArray, get} from '../helpers';
import {useSelector} from 'react-redux';

const center = {
  lat: 11.0053,
  lng: 77.5609,
};

function PublicGoogleMaps({locations, device}) {
  const {isLoaded} = useJsApiLoader({
    id: 'devices-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });
  const mapZoom = useSelector((state) => state.device.mapZoom);
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((position) => {
        if (position) {
          bounds.extend(position);
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [locations]);

  const onLoad = useCallback(function callback(newMap) {
    mapRef.current = newMap;
  }, []);

  const onUnmount = useCallback(function callback(map) {
    map.current = null;
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
      <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={mapZoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{disableDefaultUI: true, maxZoom: 18}}
      >
        {isArray(locations) && locations.length > 0 && (
            <Polyline
                path={locations}
                options={{
                  strokeColor: '#2A4CFF',
                }}
            />)}
        {isArray(locations) && locations.length > 0 && (
            <Marker
                icon={{
                  url: getImageUrl(
                      get(device, 'type', 'car'),
                      device.terminal.status,
                      device.imei,
                  ),
                  scaledSize: new window.google.maps.Size(60, 60),
                  anchor: new window.google.maps.Point(30, 30),
                }}
                id={`marker-public`}
                position={locations[locations.length - 1]}
            />)}
      </GoogleMap>
  );
}

export default memo(PublicGoogleMaps);
