import {MdLocationOn, MdOutlineMenu, MdOutlineSearch} from 'react-icons/md';
import {updateSearch, showMapOnlyView} from '../redux/slices/deviceSlice';
import {useDispatch, useSelector} from 'react-redux';
import {updateShowDrawerMenu} from '../redux/slices/generalSlice';

export default function MobileHeader() {
  const dispatch = useDispatch();
  const searchKeyword = useSelector((state) => state.device.searchKeyword);

  return (
      <div
          className="w-full bg-white shadow md:hidden block px-2 py-4 flex justify-between items-center">
        <button
            onClick={() => {
              dispatch(updateShowDrawerMenu(true));
            }}
        >
          <MdOutlineMenu size={24} color="#001C6F"/>
        </button>
        <div className="flex-1 mx-3 relative">
          <input
              className="p-2 pl-8 w-full bg-neutral-50 rounded-3xl border border-neutral-200 text-xs font-normal "
              placeholder="Enter your search"
              value={searchKeyword}
              onChange={(e) => {
                dispatch(updateSearch(e.target.value));
              }}
          />
          <span className="absolute top-1 left-2">
            <MdOutlineSearch size={24} color="#7D7D7D"/>
          </span>
        </div>
        <div
            onClick={() => {
              dispatch(showMapOnlyView());
            }}
        >
          <MdLocationOn size={24} color="#001C6F"/>
        </div>
      </div>
  );
}
