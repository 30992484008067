import {MdClose} from 'react-icons/md';
import {useDispatch} from 'react-redux';
import {
  updatePlaybackCompleted,
  updatePlaybackHistory, updatePlaybackPlaying, updatePlaybackProgress,
  updateShowingPlayback,
} from '../redux/slices/generalSlice';

export default function ClosePlaybackButton() {
  const dispatch = useDispatch();

  return (
      <div
          className="md:block hidden absolute top-5 right-5 z-[100] cursor-pointer"
          onClick={() => {
            dispatch(updateShowingPlayback(false));
            dispatch(updatePlaybackHistory({
              path: [],
              stops: [],
            }));
            dispatch(updatePlaybackPlaying(false));
            dispatch(updatePlaybackCompleted(true));
            dispatch(updatePlaybackProgress(0));
          }}
      >
        <div className="p-2 bg-blue-950 bg-opacity-70 rounded-full">
          <MdClose color="#ffffff" size={28}/>
        </div>
      </div>
  );
}
