import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {MdArrowBack} from 'react-icons/md';

import DesktopHeader from '../components/DesktopHeader';
import axios from '../axios';
import Loading from '../components/Loading';
import Summary from '../components/Report/Summary';
import {isArray} from '../helpers';
import {updateLoading} from '../redux/slices/generalSlice';
import TravellingHours from '../components/Report/TravellingHours';
import TravellingDistance from '../components/Report/TravellingDistance';
import Transactions from '../components/Report/Transactions';

export default function VehicleReport() {
  const {vehicleId} = useParams();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.general.loading);
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    dispatch(updateLoading(true));
    axios
        .get(`/vehicle/report?id=${vehicleId}`)
        .then((response) => {
          if (response?.success) {
            const reportDetails = response?.report ?? [];
            if (isArray(reportDetails) && reportDetails.length > 0) {
              setReports(reportDetails);
            }
            const transactionDetails = response?.transactions ?? [];
            if (isArray(transactionDetails) && transactionDetails.length > 0) {
              setTransactions(transactionDetails);
            }
          }
          setTimeout(() => {
            dispatch(updateLoading(false));
          }, 2000);
        })
        .catch((e) => {
          dispatch(updateLoading(false));
        });
  }, [dispatch, vehicleId]);

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  navigate(-1);
                }}
            >
              <MdArrowBack size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Vehicle Report
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 relative p-5 table-listing bg-neutral-100">
          {reports.length > 0 && (
              <div>
                <Summary reports={reports} transactions={transactions}/>
                <div className="flex md:flex-row flex-col">
                  <div className="md:w-2/3 w-full md:order-1 order-2">
                    <div className="mt-5">
                      <TravellingHours reports={reports}/>
                    </div>
                    <div className="mt-5">
                      <TravellingDistance reports={reports}/>
                    </div>
                  </div>
                  <div className="md:w-1/3 w-full md:order-2 order-1">
                    <div className="mt-5 md:ml-5">
                      <Transactions transactions={transactions}/>
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>
        {loading && <Loading/>}
      </div>
  );
}
