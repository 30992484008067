import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IoMdInformationCircle} from 'react-icons/io';
import {
  TbMap,
  TbMapCheck,
  TbTrafficLights,
  TbTrafficLightsOff,
} from 'react-icons/tb';
import {FaDirections} from 'react-icons/fa';
import {GiPathDistance} from 'react-icons/gi';
import {toast} from 'react-toastify';

import {
  updateInfobox,
  updateLoading,
  updatePolylinePath,
  updateSatelliteView,
  updateTrafficView,
} from '../redux/slices/generalSlice';
import {setPolyline} from '../redux/slices/deviceSlice';
import {get} from '../helpers';
import axios from '../axios';

export default function MapOptions() {
  const dispatch = useDispatch();
  const infobox = useSelector((state) => state.general.infobox);
  const satelliteView = useSelector((state) => state.general.satelliteView);
  const trafficView = useSelector((state) => state.general.trafficView);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const polyLine = useSelector((state) => state.device.polyLine);
  const allDevices = useSelector((state) => state.device.list);

  const device = allDevices.find((obj) => obj.imei === selectedDevice);

  const loadPolyLine = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let startDate = today.toISOString();
    today.setHours(23, 59, 59, 999);
    let endDate = today.toISOString();
    dispatch(updateLoading(true));

    // startDate = '2023-11-30T18:30:00.000Z';
    // endDate = '2023-12-26T18:29:59.999Z';
    axios.get(
        `/user/vehicle/playback/${selectedDevice}?startDate=${startDate}&endDate=${endDate}`)
        .then((response) => {
          if (get(response, 'success')) {
            let path = get(response, 'path', []);
            if (path.length > 0) {
              dispatch(updatePolylinePath(get(response, 'path', [])));
              dispatch(setPolyline(true));
            } else {
              toast.error('No polyline available for today!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
              });
            }
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          dispatch(updateLoading(false));
        });
  };

  return (
      <div
          className="absolute z-[100] md:top-0 top-24 right-0 w-full py-4 pr-4 items-center flex map-options">
        <div
            className="grid grid-cols-1 w-full gap-2">
          <button
              className="w-full cursor-pointer rounded-lg shadow p-2 bg-white"
              onClick={() => {
                dispatch(updateInfobox(!infobox));
              }}
          >
            <IoMdInformationCircle color={infobox ? '#001C6F':'#7F7F7F'}
                                   size={24}/>
          </button>
          <button
              className="w-full cursor-pointer rounded-lg shadow p-2 bg-white"
              onClick={() => {
                dispatch(updateSatelliteView(!satelliteView));
              }}
          >
            {satelliteView && <TbMapCheck color="#001C6F" size={24}/>}
            {!satelliteView && <TbMap color="#7F7F7F" size={24}/>}
          </button>
          <button
              className="w-full cursor-pointer rounded-lg shadow p-2 bg-white"
              onClick={() => {
                dispatch(updateTrafficView(!trafficView));
              }}
          >
            {trafficView && <TbTrafficLights color="#001C6F" size={24}/>}
            {!trafficView && <TbTrafficLightsOff color="#7F7F7F" size={24}/>}
          </button>
          {device && <>
            <button
                className="w-full cursor-pointer rounded-lg shadow p-2 bg-white"
                onClick={() => {
                  const lat = get(device, 'terminal.last_location.latitude', 0);
                  const long = get(device, 'terminal.last_location.longitude',
                      0);
                  window.location.href = `https://maps.google.com/?q=${lat},${long}`;
                }}
            >
              <FaDirections color="#7F7F7F" size={24}/>
            </button>
            <button
                className="w-full cursor-pointer rounded-lg shadow p-2 bg-white"
                onClick={() => {
                  if (!polyLine) {
                    loadPolyLine();
                  } else {
                    dispatch(setPolyline(false));
                  }
                }}
            >
              <GiPathDistance color={polyLine ? '#001C6F':'#7F7F7F'} size={24}/>
            </button>
          </>}
        </div>
      </div>
  );
}
