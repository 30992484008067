export default function Loading() {
  return (
      <div
          className="bg-white bg-opacity-40 absolute top-0 bottom-0 right-0 left-0 z-[110] flex justify-center items-center backdrop-blur-sm"
      >
        <div className="loader-container">
          <span className="loader" ></span>
        </div>
      </div>
  );
}
