import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import axios from '../axios';
import {
  updateLocationSharingPopup,
  updatePlaybackCompleted,
  updatePlaybackHistory,
  updatePlaybackPlaying,
  updatePlaybackPopup,
  updatePlaybackProgress,
  updateShowDrawerMenu,
  updateShowingPlayback,
  updateVehicleInfoPopup,
} from '../redux/slices/generalSlice';
import {setMapBounds, unselectSingleDevice} from '../redux/slices/deviceSlice';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [validatingTokens, setValidatingToken] = useState(true);
  const [token, setToken_] = useState(localStorage.getItem('token'));
  const [userInfo, setUserInfo_] = useState({});
  const [refreshToken, setRefreshToken] = useState(
      localStorage.getItem('refreshToken'),
  );

  // Function to set the authentication token
  const setToken = useCallback((newToken) => {
    setToken_(newToken);
    localStorage.setItem('token', newToken);
    if (newToken) {
      const newUserInfo = decodeJwtPayload(newToken);
      setUserInfo_(newUserInfo);
      if (window.hasOwnProperty('ReactNativeWebView')) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
              'type': 'login',
              'userInfo': newUserInfo,
            }),
        );
      }
    } else {
      setUserInfo_({});
    }
  }, []);

  function decodeJwtPayload(jwtToken) {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    return JSON.parse(jsonPayload);
  }

  // Function to set the refresh token
  const setNewRefreshToken = (newRefreshToken) => {
    setRefreshToken(newRefreshToken);
    localStorage.setItem('refreshToken', newRefreshToken);
  };

  // Function to remove tokens when logging out
  const logout = useCallback(() => {
    setToken(null);
    setRefreshToken(null);
    if (window.hasOwnProperty('ReactNativeWebView')) {
      const oldToken = localStorage.getItem('token');
      if (oldToken) {
        const existingUserInfo = decodeJwtPayload(oldToken);
        window.ReactNativeWebView.postMessage(JSON.stringify({
              'type': 'logout',
              'userInfo': existingUserInfo,
            }),
        );
      }
    }

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }, [setToken]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
      () => ({
        userInfo,
        token,
        setToken,
        setNewRefreshToken,
        logout,
      }),
      [logout, setToken, token, userInfo],
  );

  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        if (token) {
          // Replace with your API endpoint to check token expiration
          const response = await axios.post('/auth/check-token-expiration', {
            token,
            refreshToken,
          });
          if (response && response.newToken) {
            // If a new access token is received, set it
            setToken(response.newToken);
          }
        }
      } catch (error) {
        console.error('Token expiration check failed:', error);
        logout();
      }
    };

    const refreshTokenInterval = setInterval(() => {
      checkTokenExpiration().then(r => {
      }).catch(() => {
      });
    }, 34200000); // Check every hour

    checkTokenExpiration().then(r => {
      setValidatingToken(false);
    }).catch(() => {
      setValidatingToken(false);
      logout();
    });

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, [token, refreshToken, logout, setToken]);

  const PageContent = () => {
    const dispatch = useDispatch();
    const showingPlayback = useSelector(
        (state) => state.general.showingPlayback);
    const selectedDevice = useSelector((state) => state.device.selectedDevice);
    const playbackPopup = useSelector((state) => state.general.playbackPopup);
    const vehicleInfoPopup = useSelector(
        (state) => state.general.vehicleInfoPopup);
    const showDrawerMenu = useSelector((state) => state.general.showDrawerMenu);
    const locationSharingPopup = useSelector(
        (state) => state.general.locationSharingPopup);

    const handleGoBack = useCallback(
        (event) => {
          if (event.data === 'goBack') {
            if (showDrawerMenu) {
              dispatch(updateShowDrawerMenu(false));
            } else if (locationSharingPopup) {
              dispatch(updateLocationSharingPopup(true));
            } else if (playbackPopup) {
              dispatch(updatePlaybackPopup(false));
            } else if (vehicleInfoPopup) {
              dispatch(updateVehicleInfoPopup(false));
            } else if (showingPlayback) {
              dispatch(updateShowingPlayback(false));
              dispatch(updatePlaybackHistory({
                path: [],
                stops: [],
              }));
              dispatch(updatePlaybackPlaying(false));
              dispatch(updatePlaybackCompleted(true));
              dispatch(updatePlaybackProgress(0));
            } else if (selectedDevice) {
              dispatch(setMapBounds([]));
              dispatch(unselectSingleDevice());
            }
          }
        },
        [
          dispatch,
          locationSharingPopup,
          playbackPopup,
          selectedDevice,
          showDrawerMenu,
          showingPlayback,
          vehicleInfoPopup],
    );

    useEffect(() => {
      document.addEventListener('message', handleGoBack);

      return () => {
        document.removeEventListener('message', handleGoBack);
      };
    }, [handleGoBack]);

    return <>{children}<ToastContainer /></>;
  };

  // Provide the authentication context to the children components
  return (
      <AuthContext.Provider value={contextValue}>
        {validatingTokens && (
            <div className="h-full w-full flex items-center justify-center">
              <div className="flex items-center flex-col">
                <div className="loader-container">
                  <span className="loader" ></span>
                </div>
                <div className="mt-3">
                  <img alt="" src="https://firebasestorage.googleapis.com/v0/b/trackandgo-4a78a.appspot.com/o/Logo-text.png?alt=media" className="object-contain h-12" />
                </div>
              </div>
            </div>
        )}
        {!validatingTokens && <PageContent/>}
      </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
