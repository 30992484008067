import {MdClose} from 'react-icons/md';
import {
  hideMapOnlyView,
} from '../redux/slices/deviceSlice';
import {useDispatch} from 'react-redux';

export default function CloseMapViewButton() {
  const dispatch = useDispatch();

  return (
      <div
          className="absolute top-5 right-5 z-[100]"
          onClick={()=>{
            dispatch(hideMapOnlyView())
          }}
      >
        <div className="p-2 bg-blue-950 bg-opacity-70 rounded-full">
          <MdClose color="#ffffff" size={28} />
        </div>
      </div>
  );
}
