import {MdOutlineModeOfTravel} from 'react-icons/md';
import {LuTimerReset} from 'react-icons/lu';
import {RiRestTimeLine} from 'react-icons/ri';
import {GiSpeedometer} from 'react-icons/gi';
import React from 'react';
import {formatMsToMin, get} from '../../helpers';

export default function Summary({reports, transactions}) {
  let distanceTravelled = 0;
  let engineHours = 0;
  let movingHours = 0;
  let overSpeedDistance = 0;
  for (const report of reports) {
    distanceTravelled += get(report, 'distance_traveled', 0);
    overSpeedDistance += get(report, 'over_speed_distance', 0);
    engineHours += get(report, 'primary_engine_hours', 0);
    movingHours += get(report, 'moving_hours', 0);
  }

  let idleHours = engineHours - movingHours;
  if (idleHours < 0){
    idleHours = 100000
  }

  return (
      <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
        <div
            className="p-5 bg-white border border-neutral-100 shadow flex md:flex-row flex-col items-center justify-center rounded-xl">
          <div className="mr-5">
            <MdOutlineModeOfTravel size={64} color="#2a2a2a"/>
          </div>
          <div className="flex flex-col md:items-start items-center">
            <div className="text-black text-3xl font-bold leading-loose">
              {distanceTravelled.toFixed(1)}
            </div>
            <div className="text-zinc-500 text-sm font-bold leading-snug md:text-left text-center">
              Distance Travelled (Kms)
            </div>
          </div>
        </div>
        <div
            className="p-5 bg-white border border-neutral-100 shadow flex md:flex-row flex-col items-center justify-center rounded-xl">
          <div className="mr-5">
            <LuTimerReset size={64} color="#2a2a2a"/>
          </div>
          <div className="flex flex-col md:items-start items-center">
            <div className="text-black text-3xl font-bold leading-loose">
              {formatMsToMin(engineHours)}
            </div>
            <div className="text-zinc-500 text-sm font-bold leading-snug md:text-left text-center">
              Total Runtime (Hours)
            </div>
          </div>
        </div>
        <div
            className="p-5 bg-white border border-neutral-100 shadow flex md:flex-row flex-col items-center justify-center rounded-xl">
          <div className="mr-5">
            <RiRestTimeLine size={64} color="#2a2a2a"/>
          </div>
          <div className="flex flex-col md:items-start items-center">
            <div className="text-black text-3xl font-bold leading-loose">
              {formatMsToMin(idleHours)}
            </div>
            <div className="text-zinc-500 text-sm font-bold leading-snug md:text-left text-center">
              Total Idle (Hours)
            </div>
          </div>
        </div>
        <div
            className="p-5 bg-white border border-neutral-100 shadow flex md:flex-row flex-col items-center justify-center rounded-xl">
          <div className="mr-5">
            <GiSpeedometer size={64} color="#2a2a2a"/>
          </div>
          <div className="flex flex-col md:items-start items-center">
            <div className="text-black text-3xl font-bold leading-loose">
              {overSpeedDistance.toFixed(1)}
            </div>
            <div className="text-zinc-500 text-sm font-bold leading-snug md:text-left text-center">
             Over Speed (Kms)
            </div>
          </div>
        </div>
      </div>
  );
}
