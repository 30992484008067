import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';

import {useAuth} from '../provider/authProvider';
import axios from '../axios';
import {get} from '../helpers';

function Login() {
  const {token, setToken, setNewRefreshToken} = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordField, setPasswordField] = useState('password');
  const [login, setLogin] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/', {replace: true});
    }
  }, [navigate, token]);

  if (token) {
    return null;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setLogin(true);
    // Send a POST request to your backend for authentication
    axios.post('/auth/login', {username, password})
        .then((response) => {
          if (response?.success) {
            setToken(response?.token ?? '');
            setNewRefreshToken(response?.refreshToken ?? '');
            return navigate('/', {replace: true});
          } else {
            // Authentication failed, show an error message
            setLogin(false);
            setError('Please check your username and password!');
          }
        })
        .catch((response) => {
          let requestError = get(response, 'response.data.message', 'Please check your username and password!');
          setError(requestError);
          setLogin(false);
        });
  };

  return (
      <div className="flex items-center justify-center">
        <div className="mx-8 my-8 w-full max-w-xs">
          <form onSubmit={handleLogin}>
            <img className="w-24 h-24 rounded-full mx-auto"
                 src="https://firebasestorage.googleapis.com/v0/b/trackandgo-4a78a.appspot.com/o/track-and-goicon-2.png?alt=media"
                 alt=""
            />
            <div className="text-blue-950 text-lg font-medium text-center mt-5">
              Hey welcome back!
            </div>
            <div className="mt-7">
              <div
                  className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                Username
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                    placeholder="Example: fast_cars"
                    value={username}
                    required
                    type="text"
                    onChange={(e) => {
                      setError(null);
                      setUsername(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="mt-4">
              <div
                  className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                Password
              </div>
              <div className="relative">
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                    placeholder="Example: #p@ssw0rd#"
                    value={password}
                    required
                    type={passwordField}
                    onChange={(e) => {
                      setError(null);
                      setPassword(e.target.value);
                    }}
                />
                <span
                    className="absolute right-4 top-4"
                    onClick={() => {
                      setPasswordField((oldValue) => {
                        return oldValue === 'text' ? 'password':'text';
                      });
                    }}
                >
                {passwordField === 'password' ?
                    <AiOutlineEye color="#000000" size={24}/>:
                    <AiOutlineEyeInvisible color="#000000" size={24}/>}
              </span>
              </div>
            </div>
            <div className="mt-12 text-center">
              <button
                  type="submit" disabled={login}
                  className="w-36 p-2 bg-blue-950 rounded-3xl text-white text-lg font-bold">
                Login
              </button>
            </div>
            {error && <div className="text-red-500 text-center mt-2">
              {error}
            </div>}
          </form>
        </div>
      </div>
  );
}

export default Login;
