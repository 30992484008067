import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {MdOutlineMenu} from 'react-icons/md';
import { BsShareFill} from 'react-icons/bs';
import {NavLink, useNavigate} from 'react-router-dom';

import DesktopHeader from '../components/DesktopHeader';
import axios from '../axios';
import {
  selectSingleDevice,
  updateDeviceList,
} from '../redux/slices/deviceSlice';
import {
  updateLoading,
  updateShowDrawerMenu,
  updateVehicleTransactionPopup,
} from '../redux/slices/generalSlice';
import Loading from '../components/Loading';
import {get} from '../helpers';
import Drawer from '../components/Drawer';
import {TbReport} from 'react-icons/tb';
import AddTransactionPopup from '../components/AddTransactionPopup';
import {FaMoneyBillTransfer} from 'react-icons/fa6';

export default function Vehicles() {
  const listLoaded = useSelector((state) => state.device.listLoaded);
  const devices = useSelector((state) => state.device.list);
  const vehicleTransactionPopup = useSelector((state) => state.general.vehicleTransactionPopup);
  const loading = useSelector((state) => state.general.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!listLoaded) {
      dispatch(updateLoading(true));
      axios
          .get('/user/vehicles')
          .then((response) => {
            if (response?.success) {
              const userVehicles = response?.vehicles ?? [];
              if (userVehicles.length > 0) {
                dispatch(updateDeviceList(userVehicles));
              }
            }
            dispatch(updateLoading(false));
          })
          .catch((e) => {
            dispatch(updateLoading(false));
          });
    }
  }, [dispatch, listLoaded]);

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  dispatch(updateShowDrawerMenu(true));
                }}
            >
              <MdOutlineMenu size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Device Management
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 relative p-5 table-listing">
          <div
              className="text-blue-950 text-xl font-bold md:mb-5 md:block hidden">
            Device Management
          </div>
          <table className="w-full bg-white rounded-lg shadow border-collapse">
            <thead>
            <tr>
              <th>
                <div className="text-blue-950 text-base font-bold">
                  Vehicle Name
                </div>
              </th>
              <th>
                <div className="text-zinc-500 text-base font-bold">
                  Activation Date
                </div>
              </th>
              <th>
                <div className="text-zinc-500 text-base font-bold">
                  Expiry Date
                </div>
              </th>
              <th>
              </th>
            </tr>
            </thead>
            {devices.length > 0 && (
                <tbody>
                {devices.map((device, index) => {
                  let activatedDate = new Date(
                      get(device, 'activated_at'));
                  let expiredDate = new Date(get(device, 'expired_at'));

                  return (
                      <tr key={`device-details-${index}`}>
                        <td>
                          <div className="text-blue-950 text-sm font-normal">
                            {get(device, 'name', '-')}
                          </div>
                        </td>
                        <td>
                          <div className="text-green-600 text-sm font-normal">
                            {activatedDate.toLocaleDateString()}
                          </div>
                        </td>
                        <td>
                          <div className="text-red-600 text-sm font-normal">
                            {expiredDate.toLocaleDateString()}
                          </div>
                        </td>
                        <td>
                          <div className="flex">
                            <button
                                className="p-2"
                                onClick={() => {
                                  navigate(`/share/vehicle/${device.imei}`);
                                }}
                            >
                              <BsShareFill color="#7F7F7F" size={18}/>
                            </button>
                            <button
                                className="p-2"
                                onClick={() => {
                                  dispatch(selectSingleDevice(device.imei));
                                  dispatch(updateVehicleTransactionPopup(true));
                                }}
                            >
                              <FaMoneyBillTransfer color="#7F7F7F" size={24}/>
                            </button>
                            <NavLink to={`/report/${device.imei}`} className="p-2">
                              <TbReport color="#7F7F7F" size={24}/>
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
            )}
            {devices.length <= 0 && (
                <tbody>
                <tr>
                  <td colSpan={4}>
                    <div>
                      No vehicles found!
                    </div>
                  </td>
                </tr>
                </tbody>
            )}
          </table>
        </div>
        {vehicleTransactionPopup && <AddTransactionPopup />}
        {loading && <Loading/>}
        <Drawer/>
      </div>
  );
}
