import {NavLink, useNavigate} from 'react-router-dom';
import {AiTwotoneHome} from 'react-icons/ai';
import {BiLogOutCircle, BiSolidUser} from 'react-icons/bi';
import {BsGearFill} from 'react-icons/bs';
import {FaCarSide} from 'react-icons/fa';
import {useAuth} from '../provider/authProvider';

export default function DesktopHeader() {
  const {logout} = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
    navigate('/login', {replace: true});
  };

  return (
      <div className="flex justify-between flex-col h-full px-2 py-6 menu">
        <div>
          <div className="mb-2">
            <NavLink to="/" className={({isActive}) => {
              return isActive ? 'active':'';
            }}>
              <div className="menu-item">
                <AiTwotoneHome size={24}/>
              </div>
            </NavLink>
          </div>
          <div className="mb-2">
            <NavLink to="/vehicles" className={({isActive}) => {
              return isActive ? 'active':'';
            }}>
              <div className="menu-item">
                <FaCarSide size={24}/>
              </div>
            </NavLink>
          </div>
        </div>
        <div>
          <div className="mt-2">
            <NavLink to="/edit-profile" className={({isActive}) => {
              return isActive ? 'active':'';
            }}>
              <div className="menu-item">
                <BiSolidUser size={24}/>
              </div>
            </NavLink>
          </div>
          <div className="mt-2">
            <NavLink to="/settings" className={({isActive}) => {
              return isActive ? 'active':'';
            }}>
              <div className="menu-item">
                <BsGearFill size={24}/>
              </div>
            </NavLink>
          </div>
          <div className="mt-2">
            <div className="menu-item cursor-pointer" onClick={handleLogout}>
              <BiLogOutCircle size={24}/>
            </div>
          </div>
        </div>
      </div>
  );
}
