import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import DatePicker from 'react-datepicker';

import Popup from './Popup';
import {
  updateLoading,
  updateVehicleRemainderPopup,
} from '../redux/slices/generalSlice';
import {remainderCategories} from '../constants';
import axios from '../axios';
import {get, set, toInt} from '../helpers';
import ListRemaindersTabContent from './ListRemaindersTabContent';

const dueDate = new Date();
dueDate.setFullYear(dueDate.getFullYear() + 1);

const initialState = {
  id: null,
  category: 'fitness_certificate',
  type: 'one_time',
  repeat_period: 'year',
  repeat_value: 1,
  due_date: dueDate.toISOString(),
  note: '',
  remind_before_days: 3,
};

export default function AddRemainderPopup() {
  const [details, setDetails] = useState(initialState);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const [formErrors, setFormErrors] = useState({});
  const [tab, setTab] = useState('add');
  const [task, setTask] = useState('add');

  const dispatch = useDispatch();
  const closeRemainderPopup = () => {
    dispatch(updateVehicleRemainderPopup(false));
  };

  const saveVehicleRemainder = (e) => {
    e.preventDefault();
    const data = {...details, vehicle_id: selectedDevice};
    dispatch(updateLoading(true));
    axios.post(`/vehicle/add-remainder`, data)
        .then((response) => {
          if (get(response, 'success')) {
            let message;
            if (task === 'add') {
              message = 'Remainder created successfully!';
              setDetails(initialState);
            } else {
              message = 'Remainder updated successfully!';
            }
            toast.success(message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          } else if (get(response, 'errors')) {
            setFormErrors(response?.errors);
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          console.log(e.message);
          dispatch(updateLoading(false));
          toast.error('Remainder creation failed!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  };

  const nextDue = new Date(details.due_date);
  const repeatPeriod = details.repeat_period;
  const repeatDays = toInt(details.repeat_value);
  switch (repeatPeriod) {
    default:
    case 'year':
      nextDue.setFullYear(nextDue.getFullYear() + repeatDays);
      break;
    case 'week':
      nextDue.setDate(nextDue.getDate() + (repeatDays * 7));
      break;
    case 'month':
      nextDue.setMonth(nextDue.getMonth() + repeatDays);
      break;
    case 'day':
      nextDue.setDate(nextDue.getDate() + repeatDays);
      break;
  }

  const ErrorMessage = ({message}) => {
    if (!message || message.length <= 0) {
      return null;
    }
    return (<div className="text-orange text-xs mt-1">{message}</div>);
  };

  const updateData = (column, value) => {
    setDetails((ieData) => {
      const newIeData = {...ieData};
      set(newIeData, column, value);
      return newIeData;
    });
  };

  return (
      <Popup title="Remainder" onClose={closeRemainderPopup} show={true}>
        <div className="w-full">
          <div className="flex mt-2">
            <button
                type="button"
                onClick={() => {
                  setTab('add');
                }}
                className={`${tab === 'add' ?
                    'text-blue-950 font-bold border-b-2 border-blue-950':
                    'text-zinc-500 border-b border-neutral-200'} flex-1 py-2`}>
              {task === 'add' ? 'Add New':'Edit Remainder'}
            </button>
            <button
                type="button"
                onClick={() => {
                  setTask('add');
                  setDetails(initialState);
                  setTab('list');
                }}
                className={`${tab === 'list' ?
                    'text-blue-950 font-bold border-b-2 border-blue-950':
                    'text-zinc-500 border-b border-neutral-200'} flex-1 py-2`}>
              View All
            </button>
          </div>
        </div>
        {tab === 'add' && (
            <form onSubmit={saveVehicleRemainder}>
              <div className="w-full">
                <div className="mt-4">
                  <div
                      className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                    Category *
                  </div>
                  <div>
                    <select
                        className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                        value={get(details, 'category')}
                        required
                        onChange={(e) => {
                          updateData('category', e.target.value);
                        }}
                    >
                      <option value=""> --- SELECT ---</option>
                      {
                        remainderCategories.map((category, index) => {
                          return (<option
                              value={category.name}
                              key={`remainder-category-option-${index}`}>
                            {category.label}
                          </option>);
                        })
                      }
                    </select>
                  </div>
                  <ErrorMessage message={get(formErrors, 'category', null)}/>
                </div>
                <div className="mt-4">
                  <div
                      className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                    Type *
                  </div>
                  <div className="w-full flex">
                    <button
                        type="button"
                        onClick={() => {
                          updateData('type', 'one_time');
                        }}
                        className={`flex-1 ${get(details, 'type') ===
                        'one_time' ?
                            'bg-blue-600 text-white':
                            'bg-neutral-200 text-zinc-500'} rounded text-center text-base font-normal py-2 px-5 mx-2 font-bold`}
                    >
                      One-Time
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                          updateData('type', 'repeat');
                        }}
                        className={`flex-1 ${get(details, 'type') === 'repeat' ?
                            'bg-blue-600 text-white':
                            'bg-neutral-200 text-zinc-500'} rounded text-center text-base font-normal py-2 px-5 mx-2 font-bold`}
                    >
                      Repeat
                    </button>
                  </div>
                  <ErrorMessage message={get(formErrors, 'type', null)}/>
                </div>
                <div className="mt-4">
                  <div
                      className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                    Due Date *
                  </div>
                  <div className="">
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={new Date(get(details, 'due_date'))}
                        onChange={(date) => {
                          updateData('due_date', date.toISOString());
                        }}
                        minDate={new Date()}
                    />
                  </div>
                  <ErrorMessage message={get(formErrors, 'due_date', null)}/>
                </div>
                {details.type === 'repeat' && (
                    <div className="mt-4">
                      <div
                          className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                        Remind Every *
                      </div>
                      <div className="flex items-center justify-center">
                        <div className="flex-1 mr-2">
                          <input
                              className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                              placeholder="Example: 7"
                              value={get(details, 'repeat_value')}
                              required
                              min={1}
                              type="number"
                              onChange={(e) => {
                                const val = toInt(e.target.value, 0);
                                updateData('repeat_value', val < 0 ? 1:val);
                              }}
                          />
                        </div>
                        <div className="text-zinc-500 text-base font-semibold">
                          <select
                              className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                              value={get(details, 'repeat_period')}
                              required
                              onChange={(e) => {
                                updateData('repeat_period', e.target.value);
                              }}
                          >
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                          </select>
                        </div>
                      </div>
                      <div className="text-zinc-500 text-xs font-semibold mt-2">
                        Next Due: {nextDue.toLocaleDateString()}
                      </div>
                      <ErrorMessage
                          message={get(formErrors, 'repeat_period', null)}/>
                    </div>
                )}
                <div className="mt-4">
                  <div
                      className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                    Start Send Reminders Before *
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="flex-1 mr-2">
                      <input
                          className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                          placeholder="Example: 7"
                          value={get(details, 'remind_before_days')}
                          required
                          type="number"
                          onChange={(e) => {
                            updateData('remind_before_days',
                                toInt(e.target.value, 0));
                          }}
                      />
                    </div>
                    <div className="text-zinc-500 text-base font-semibold">
                      Days
                    </div>
                  </div>
                  <ErrorMessage
                      message={get(formErrors, 'remind_before_days', null)}/>
                </div>
                <div className="mt-4">
                  <div
                      className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                    Note
                  </div>
                  <div>
                    <input
                        className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                        placeholder="Example: FC expiring remainder"
                        value={get(details, 'note')}
                        type="text"
                        onChange={(e) => {
                          updateData('note', e.target.value);
                        }}
                    />
                  </div>
                  <ErrorMessage message={get(formErrors, 'date', null)}/>
                </div>
                <div className="mt-5 mb-2 text-center">
                  <button
                      className="bg-blue-950 rounded-2xl text-center text-white text-base font-normal py-2 px-5 mx-2 font-bold"
                      type="submit"
                  >
                    Save
                  </button>
                  <button
                      className="bg-neutral-200 rounded-2xl text-center text-zinc-500 text-base font-normal py-2 px-5 mx-2 font-bold"
                      onClick={() => {
                        if (task === 'add') {
                          closeRemainderPopup();
                        } else {
                          setTask('add');
                          setDetails(initialState);
                        }
                      }}
                      type="button"
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
        )}
        {tab === 'list' && (
            <div className="mt-4">
              <ListRemaindersTabContent onEdit={(remainderDetails) => {
                setTask('edit');
                setDetails(remainderDetails);
                setTab('add');
              }}/>
            </div>
        )}
      </Popup>
  );
}
