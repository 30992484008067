import {createSlice} from '@reduxjs/toolkit';
import {getImageUrl, get, getDeviceLastLocation} from '../../helpers';

export const deviceSlice = createSlice({
  name: 'device', initialState: {
    value: 0,
    searchKeyword: '',
    list: [],
    listLoaded: false,
    polyLine: false,
    selectedDevice: null,
    showMapOnlyView: false,
    mapZoom: 10,
    mapBounds: [],
    locations: {},
    alarms: [],
  }, reducers: {
    addNewAlarm: (state, action) => {
      if (state.alarms.length <= 0) {
        state.alarms.push(action.payload);
      } else {
        let existingAlarm = state.alarms.find(
            (item) => item.id === action.payload.id);
        if (!existingAlarm) {
          state.alarms.push(action.payload);
        }
      }
    },
    removeAlarm: (state, action) => {
      if (state.alarms.length > 0) {
        const allAlarms = [];
        state.alarms.forEach((alarm) => {
          if (alarm.id !== action.payload) {
            allAlarms.push(alarm);
          }
        });
        state.alarms = allAlarms;
      }
    },

    updateDeviceTerminalInfo: (state, action) => {
      const payload = action.payload;
      const deviceId = get(payload, 'imei', '');
      const newTerminalStatus = get(payload, 'newStatus', null);
      const deviceToUpdate = state.list.find((item) => item.imei === deviceId);
      if (deviceToUpdate && newTerminalStatus) {
        deviceToUpdate.terminal = newTerminalStatus;

        const imageUrl = getImageUrl(
            newTerminalStatus.type,
            newTerminalStatus.status,
            newTerminalStatus.imei,
        );
        const marker = document.querySelector(`[src="${imageUrl}"]`);

        if (marker) {
          const rotation = get(newTerminalStatus, 'last_location.course', 0);
          marker.style.transform = `rotate(${rotation - 90}deg)`;
        }
      }
    }, updateDeviceTripSummaryInfo: (state, action) => {
      const payload = action.payload;
      const deviceId = get(payload, 'imei', '');
      const newTripSummaryStatus = get(payload, 'newTripSummary', null);
      const deviceToUpdate = state.list.find((item) => item.imei === deviceId);
      if (deviceToUpdate && newTripSummaryStatus) {
        deviceToUpdate.trip_summary = newTripSummaryStatus;
      }
    },
    addNewDeviceLocation: (state, action) => {
      const payload = action.payload;
      const deviceId = get(payload, 'imei', '');
      const newLocation = get(payload, 'newLocation', null);
      if (newLocation && deviceId) {
        if (!state['locations'][deviceId]) {
          state['locations'][deviceId] = [];
        }

        state['locations'][deviceId].push(newLocation);
        // if (state['locations'][deviceId].length > 50) {
        //   state['locations'][deviceId].shift();
        // }

        const device = state.list.find((item) => {
          return item.imei === deviceId;
        });

        if (device) {
          const imageUrl = getImageUrl(
              device.type,
              device.status,
              device.imei,
          );
          const marker = document.querySelector(`[src="${imageUrl}"]`);

          if (marker) {
            let rotation = Number(newLocation.bearing);
            marker.style.transform = `rotate(${rotation - 90}deg)`;
          }
        }
      }
    },
    updateSearch: (state, action) => {
      state.searchKeyword = action.payload;
    }, updateDeviceList: (state, action) => {
      state.listLoaded = true;
      state.list = action.payload;
      if (state.selectedDevice) {
        for (const device of action.payload) {
          if (device.imei === state.selectedDevice) {
            const lastLocation = getDeviceLastLocation(device);
            if (lastLocation) {
              state.mapBounds = [lastLocation];
            }
          }
        }
      }
    }, selectSingleDevice: (state, action) => {
      state.selectedDevice = action.payload;
    }, setMapZoom: (state, action) => {
      state.mapZoom = action.payload;
    }, setMapBounds: (state, action) => {
      state.mapBounds = action.payload;
    }, unselectSingleDevice: (state) => {
      state.selectedDevice = null;
      state.polyLine = false;
    }, setPolyline: (state, action) => {
      state.polyLine = action.payload;
    }, hideMapOnlyView: (state) => {
      state.showMapOnlyView = false;
    }, showMapOnlyView: (state) => {
      state.showMapOnlyView = true;
    }, updateDeviceInfo: (state, action) => {
      const payload = action.payload;
      const deviceToUpdate = state.list.find((item) => {
        return item.imei === payload.imei;
      });
      if (deviceToUpdate) {
        deviceToUpdate.name = payload.name;
        deviceToUpdate.type = payload.type;
        deviceToUpdate.terminal.odometer = payload.odometer;
        deviceToUpdate.speed_limit = payload.speed_limit;
        deviceToUpdate.group = payload.group;
        deviceToUpdate.driver.name = payload.driver.name;
        deviceToUpdate.driver.phone = payload.driver.phone;
      }
    },
    updateSafeParkingStatus: (state, action) => {
      const payload = action.payload;
      const deviceToUpdate = state.list.find((item) => {
        return item.imei === payload.imei;
      });
      if (deviceToUpdate) {
        deviceToUpdate.safe_parking_location = payload.safe_parking_location;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSearch,
  selectSingleDevice,
  unselectSingleDevice,
  showMapOnlyView,
  hideMapOnlyView,
  setMapZoom,
  setMapBounds,
  updateDeviceList,
  updateDeviceInfo,
  updateSafeParkingStatus,
  addNewAlarm,
  removeAlarm,
  updateDeviceTerminalInfo,
  addNewDeviceLocation,
  updateDeviceTripSummaryInfo,
  setPolyline,
} = deviceSlice.actions;

export default deviceSlice.reducer;
