import React, {useState} from 'react';
import {MdOutlineSearch} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';

import Accordion from './Accordion';
import DeviceCard from './DeviceCard';
import {
  selectSingleDevice, setMapBounds,
  unselectSingleDevice,
  updateSearch,
} from '../redux/slices/deviceSlice';
import {getDeviceLastLocation} from '../helpers';

export default function DevicesList() {
  const tabs = [
    {key: 'all', label: 'All'},
    {key: 'moving', label: 'Moving'},
    {key: 'idle', label: 'Idle'},
    {key: 'parked', label: 'Parked'},
    {key: 'offline', label: 'Offline'},
  ];
  const [activeTab, setActiveTab] = useState('all');
  const devices = useSelector((state) => state.device.list);
  const searchKeyword = useSelector((state) => state.device.searchKeyword);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const dispatch = useDispatch();

  function groupDevices(inputArray) {
    const outputObject = {};

    // Group items by the 'group' property
    inputArray.forEach((item) => {
      const groupName = item.group.toLowerCase(); // Convert group name to lowercase for case-insensitive grouping

      if (!outputObject[groupName]) {
        outputObject[groupName] = {
          group: item.group,
          items: [],
        };
      }

      outputObject[groupName].items.push(item);
    });

    return Object.values(outputObject);
  }

  function filterDevices(devices) {
    const filteredDevices = devices.filter((device) => {
      let namePass = true;
      let statusPass = true;
      if (searchKeyword.length > 0) {
        const regex = new RegExp(searchKeyword, 'i');
        namePass = regex.test(device.name);
      }

      if (activeTab !== 'all') {
        statusPass = device.status === activeTab;
      }

      return namePass && statusPass;
    });

    return groupDevices(filteredDevices);
  }

  const allDevices = filterDevices(devices);
  const getDevicesCount = (status) => {
    if (status === 'all') {
      return devices.length;
    }
    let count = 0;
    for (const device of devices) {
      if (device.terminal.status === status) {
        count += 1;
      }
    }
    return count;
  };

  return (
      <div
          className="absolute z-[100] top-0 left-0 bottom-0 md:w-96 w-full md:py-6 md:pl-6">
        <div className="bg-white md:rounded-2xl shadow h-full md:py-4 py-0">
          <div className="flex md:px-0 px-2">
            {
              tabs.map((tab) => {
                return (
                    <button type="button"
                        key={`tab-${tab.key}`}
                        onClick={() => {
                          setActiveTab(tab.key);
                          dispatch(unselectSingleDevice());
                          dispatch(setMapBounds([]));
                        }}
                        className={`${activeTab === tab.key ?
                            'text-blue-950 font-bold border-b-2 border-blue-950':
                            'text-zinc-500 border-b border-neutral-200'} py-2 text-center flex-1 flex flex-col items-center justify-center`}
                    >
                      <span>
                        {tab.label}
                      </span>
                      <span className="mt-1 text-xs">
                        [{getDevicesCount(tab.key)}]
                      </span>
                    </button>
                );
              })
            }
          </div>
          <div className="w-full px-4 py-4 md:block hidden">
            <div className="relative">
              <input
                  className="p-3 pl-8 w-full bg-neutral-50 rounded-3xl border border-neutral-200 text-xs font-normal "
                  placeholder="Enter your search"
                  value={searchKeyword}
                  onChange={(e) => {
                    dispatch(updateSearch(e.target.value));
                    dispatch(unselectSingleDevice());
                    dispatch(setMapBounds([]));
                  }}
              />
              <span className="absolute top-2 left-2">
              <MdOutlineSearch size={24} color="#7D7D7D"/>
            </span>
            </div>
          </div>
          <div className="w-full md:py-0 py-2 device-list-container">
            {allDevices.length > 0 ? (allDevices.map((device, index) => {
              return (
                  <Accordion
                      title={device.group} openDefault={index === 0}
                      key={`device-group-${index}`}
                      accordionId={index}
                  >
                    {
                      device.items.map((device) => {
                        return (
                            <DeviceCard
                                device={device}
                                key={`device-group-${index}-item-${device.imei}`}
                                selected={selectedDevice === device.imei}
                                onSelect={() => {
                                  if (selectedDevice === device.imei) {
                                    dispatch(unselectSingleDevice());
                                    dispatch(setMapBounds([]));
                                  } else {
                                    const lastLocation = getDeviceLastLocation(
                                        device);
                                    if (lastLocation) {
                                      dispatch(setMapBounds([lastLocation]));
                                    }
                                    dispatch(selectSingleDevice(device.imei));
                                  }
                                }}
                            />
                        );
                      })
                    }
                  </Accordion>
              );
            })):(
                <div className="text-center w-full mt-5">
                  No vehicles found!
                </div>
            )}
          </div>
        </div>
      </div>
  );
}
