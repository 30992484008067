import {useDispatch, useSelector} from 'react-redux';
import React, {useState} from 'react';
import {toast} from 'react-toastify';

import Popup from './Popup';
import {
  updateLoading,
  updateVehicleInfoPopup,
} from '../redux/slices/generalSlice';
import {updateDeviceInfo} from '../redux/slices/deviceSlice';
import axios from '../axios';
import {isNumber, isString, get, set} from '../helpers';

export default function EditVehiclePopup() {
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const allDevices = useSelector((state) => state.device.list);
  const deviceDetails = allDevices.find((item) => item.imei === selectedDevice);

  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [vehicleName, setVehicleName] = useState(
      get(deviceDetails, 'name', ''),
  );
  const [vehicleType, setVehicleType] = useState(
      get(deviceDetails, 'type', ''),
  );
  const [vehicleOdometer, setVehicleOdometer] = useState(
      get(deviceDetails, 'terminal.odometer', 0),
  );
  const [vehicleSpeedLimit, setVehicleSpeedLimit] = useState(
      get(deviceDetails, 'speed_limit', 60),
  );
  const [vehicleGroup, setVehicleGroup] = useState(
      get(deviceDetails, 'group', ''),
  );
  const [vehicleDriver, setVehicleDriver] = useState(
      get(deviceDetails, 'driver.name', ''),
  );
  const [vehicleDriverPhone, setVehicleDriverPhone] = useState(
      get(deviceDetails, 'driver.phone', ''),
  );

  const closeEditVehiclePopup = () => {
    dispatch(updateVehicleInfoPopup(false));
  };

  const saveVehicleInfo = (e) => {
    e.preventDefault();
    let error = false;
    let newFormErrors = {};
    if (!isString(vehicleName) || vehicleName.length <= 0 ||
        vehicleName.length > 50) {
      error = true;
      set(newFormErrors, 'name', 'Invalid vehicle name!');
    }
    if (!isString(vehicleGroup) || vehicleGroup.length <= 0 ||
        vehicleGroup.length > 50) {
      error = true;
      set(newFormErrors, 'group', 'Invalid vehicle group name!');
    }
    if (isString(vehicleDriver) && vehicleDriver.length > 50) {
      error = true;
      set(newFormErrors, 'driver_name', 'Invalid driver name!');
    }
    if (isString(vehicleDriverPhone) && vehicleDriverPhone.length > 13) {
      error = true;
      set(newFormErrors, 'driver_phone_number',
          'Invalid driver phone number!');
    }
    if (!isString(vehicleType) ||
        !['bike', 'bus', 'truck', 'van', 'car'].includes(vehicleType)) {
      error = true;
      set(newFormErrors, 'type', 'Invalid vehicle type!');
    }
    if (!isNumber(vehicleOdometer) || vehicleOdometer < 0) {
      error = true;
      set(newFormErrors, 'odometer', 'Invalid odometer reading!');
    }
    if (!isNumber(vehicleSpeedLimit) || vehicleSpeedLimit < 10) {
      error = true;
      set(newFormErrors, 'speed_limit', 'Invalid speed limit!');
    }
    if (error) {
      setFormErrors(newFormErrors);
      return false;
    }
    const data = {
      name: vehicleName,
      type: vehicleType,
      odometer: Number(vehicleOdometer),
      speed_limit: Number(vehicleSpeedLimit),
      group: vehicleGroup,
      driver: {
        name: vehicleDriver,
        phone: vehicleDriverPhone,
      },
      imei: selectedDevice,
    };
    dispatch(updateLoading(true));
    axios.put(
        `/user/vehicle/update/${selectedDevice}`, data)
        .then((response) => {
          if (get(response, 'success')) {
            dispatch(updateDeviceInfo(data));
            dispatch(updateVehicleInfoPopup(false));
            toast.success('Vehicle details updated successfully!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          } else if (get(response, 'errors')) {
            setFormErrors(response.errors);
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          console.log(e.message);
          dispatch(updateLoading(false));
          toast.error('Vehicle details update failed!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  };

  const ErrorMessage = ({message}) => {
    if (!message || message.length <= 0) {
      return null;
    }
    return (<div className="text-orange text-xs mt-1">{message}</div>);
  };

  return (
      <Popup title="Vehicle Information" onClose={closeEditVehiclePopup} show={true}>
        <form onSubmit={saveVehicleInfo}>
          <div className="w-full">
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Vehicle Name *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: TN 42 XQ 5767"
                    value={vehicleName ?? ''}
                    required
                    type="text"
                    onChange={(e) => {
                      setVehicleName(e.target.value);
                      set(formErrors, 'name', null);
                      setFormErrors(formErrors);
                    }}
                />
              </div>
              <ErrorMessage message={get(formErrors, 'name', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Odometer (KM) *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: 7456.5"
                    value={
                      isNumber(vehicleOdometer) ?
                          Number(vehicleOdometer).toFixed(0):
                          '0'
                    }
                    required
                    min={0}
                    type="number"
                    onChange={(e) => {
                      setVehicleOdometer(e.target.value);
                      set(formErrors, 'odometer', null);
                      setFormErrors(formErrors);
                    }}
                />
              </div>
              <ErrorMessage message={get(formErrors, 'odometer', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Group Name *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: School bus"
                    value={vehicleGroup ?? ''}
                    required
                    type="text"
                    onChange={(e) => {
                      setVehicleGroup(e.target.value);
                      set(formErrors, 'group', null);
                      setFormErrors(formErrors);
                    }}
                />
              </div>
              <ErrorMessage message={get(formErrors, 'group', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Vehicle Type *
              </div>
              <div>
                <select
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    value={vehicleType ?? ''}
                    required
                    onChange={(e) => {
                      setVehicleType(e.target.value);
                      set(formErrors, 'type', null);
                      setFormErrors(formErrors);
                    }}
                >
                  <option value="bike">Bike</option>
                  <option value="car">Car</option>
                  <option value="van">Van</option>
                  <option value="bus">Bus</option>
                  <option value="truck">Truck</option>
                </select>
              </div>
              <ErrorMessage message={get(formErrors, 'type', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Speed limit (Km) *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: 60"
                    value={vehicleSpeedLimit ?? ''}
                    required
                    type="number"
                    onChange={(e) => {
                      setVehicleSpeedLimit(e.target.value);
                      set(formErrors, 'speed_limit', null);
                      setFormErrors(formErrors);
                    }}
                />
              </div>
              <ErrorMessage
                  message={get(formErrors, 'speed_limit', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Driver Name
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: Kumar"
                    value={vehicleDriver ?? ''}
                    type="text"
                    onChange={(e) => {
                      setVehicleDriver(e.target.value);
                      set(formErrors, 'driver.name', null);
                      setFormErrors(formErrors);
                    }}
                />
              </div>
              <ErrorMessage
                  message={get(formErrors, 'driver.name', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Driver Phone Number
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: 9876543210"
                    value={vehicleDriverPhone ?? ''}
                    type="tel"
                    onChange={(e) => {
                      setVehicleDriverPhone(e.target.value);
                      set(formErrors, 'driver.phone', null);
                      setFormErrors(formErrors);
                    }}
                />
              </div>
            </div>
            <ErrorMessage
                message={get(formErrors, 'driver.phone', null)}/>
          </div>
          <div className="mt-5 mb-2 text-center">
            <button
                className="bg-blue-950 rounded-2xl text-center text-white text-base font-normal py-2 px-5 mx-2 font-bold"
                onClick={() => {
                }}
            >
              Save
            </button>
            <button
                className="bg-neutral-200 rounded-2xl text-center text-zinc-500 text-base font-normal py-2 px-5 mx-2 font-bold"
                onClick={() => {
                }}
            >
              Close
            </button>
          </div>
        </form>
      </Popup>
  );
}
