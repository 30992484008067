/**
 * Gets the value at the specified path of an object. If the value does not exist,
 * the default value is returned.
 *
 * @param {Object} object - The object to query.
 * @param {string|string[]} path - The path of the property to get.
 * @param {*} [defaultValue] - The value to return if the path is not found.
 * @returns {*} The value at the specified path or the default value.
 */
export function get(object, path, defaultValue = undefined) {
  const pathArray = Array.isArray(path) ? path:path.split('.');
  let value = object;

  for (const key of pathArray) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return defaultValue;
    }
  }

  return value !== undefined ? value:defaultValue;
}

/**
 * Sets the value of a nested property within an object.
 * If the property doesn't exist, it creates the necessary nested objects.
 *
 * @param {object} object - The object to modify.
 * @param {string|string[]} path - The path of the property to set.
 * @param {any} value - The value to set at the specified path.
 * @returns {object} The modified object.
 */
export function set(object, path, value) {
  if (!isObject(object)) {
    throw new Error('Invalid object');
  }

  const pathArray = Array.isArray(path) ? path:path.split('.');
  let currentObj = object;

  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];
    if (!isObject(currentObj[key])) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  currentObj[pathArray[pathArray.length - 1]] = value;
  return object;
}

/**
 * Check if the given value is an array.
 * @param {*} value - The value to be checked.
 * @returns {boolean} - True if the value is an array, false otherwise.
 */
export function isArray(value) {
  return Array.isArray(value);
}

/**
 * Checks if a given value is a string.
 *
 * @param {*} value - The value to be checked.
 * @returns {boolean} - True if the value is a string, false otherwise.
 */
export function isString(value) {
  return typeof value === 'string';
}

/**
 * Checks if a given value is an object.
 *
 * @param {*} value - The value to be checked.
 * @returns {boolean} - True if the value is an object, false otherwise.
 */
export function isObject(value) {
  return typeof value === 'object' && value !== null && !isArray(value);
}

/**
 * Checks if a given value is a valid Date object or can be converted to one.
 *
 * @param {any} value - The value to be checked.
 * @returns {boolean} True if the value is a valid Date or can be converted to one, false otherwise.
 */
export function isValidDate(value) {
  if (typeof value === 'string' || typeof value === 'number') {
    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime());
  } else if (value instanceof Date && !isNaN(value)) {
    return true;
  }

  return false;
}

/**
 * Extracts the 'value' keys from an array of objects and returns them in a new array.
 *
 * @param {Array} arr - The array of objects.
 * @returns {Array} An array containing the 'value' keys from the input objects.
 */
export function extractValuesFromSelectOptions(arr) {
  return arr.map(item => item.value);
}

/**
 * Converts a string input to an integer.
 *
 * @param {string} input - The input string to be converted.
 * @param {number} defaultValue - Default value  to return.
 * @returns {number} The integer value if conversion is successful, or null if the conversion fails.
 */
export function toInt(input, defaultValue = 0) {
  // Use parseInt to convert the input to an integer
  const integerValue = parseInt(input, 10); // The second argument (10) specifies base 10.

  // Check if the conversion was successful
  if (!isNaN(integerValue)) {
    return integerValue;
  } else {
    // Handle the case where the input cannot be converted to an integer
    return defaultValue;
  }
}

/**
 * Converts a string input to a floating-point number (float).
 *
 * @param {string} input - The input string to be converted.
 * @param {number} defaultValue - Default value  to return.
 * @returns {number} The float value if conversion is successful, or null if the conversion fails.
 */
export function toFloat(input, defaultValue = 0) {
  // Use parseFloat to convert the input to a float
  const floatValue = parseFloat(input);

  // Check if the conversion was successful
  if (!isNaN(floatValue)) {
    return floatValue;
  } else {
    // Handle the case where the input cannot be converted to a float
    return defaultValue;
  }
}

/**
 * Checks if the given input is a valid number.
 *
 * @param {any} input - The input value to be validated.
 * @returns {boolean} - Returns true if the input is a valid number, otherwise false.
 */
export function isNumber(input) {
  return /^-?\d*\.?\d+$/.test(input);
}

/**
 * Compares two arrays to check if they are similar (have the same elements in the same order).
 *
 * @param {Array} array1 - The first array to compare.
 * @param {Array} array2 - The second array to compare.
 * @returns {boolean} - Returns true if the arrays are similar, otherwise false.
 */
export function areArraysSimilar(array1, array2) {
  // Check if the arrays have the same length.
  if (array1.length !== array2.length) {
    return false;
  }

  // Iterate through the arrays and compare each element.
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false; // Elements at the same index are not equal.
    }
  }

  // If all elements are equal, the arrays are similar.
  return true;
}

/**
 * Calculate the distance between two geographical coordinates on Earth.
 *
 * @param {number} lookupLat - The latitude of the lookup location.
 * @param {number} lookupLng - The longitude of the lookup location.
 * @param {number} officeLat - The latitude of the office location.
 * @param {number} officeLng - The longitude of the office location.
 * @returns {number} The distance between the two points in kilometers.
 */
export function calculateDistance(lookupLat, lookupLng, officeLat, officeLng) {
  if (!isNumber(lookupLat) || !isNumber(lookupLat) || !isNumber(lookupLat) ||
      !isNumber(lookupLat)) {
    return 0;
  }

  // The radius of the Earth in kilometers.
  const radius = 6371.01;

  // Convert the latitude and longitude to radians.
  const lookupLatRadians = Math.PI * lookupLat / 180;
  const officeLatRadians = Math.PI * officeLat / 180;
  const lookupLngRadians = Math.PI * lookupLng / 180;
  const officeLngRadians = Math.PI * officeLng / 180;

  // Calculate the distance between the two points.
  const dlat = lookupLatRadians - officeLatRadians;
  const dlng = lookupLngRadians - officeLngRadians;
  const a = Math.sin(dlat / 2) ** 2 + Math.cos(lookupLatRadians) *
      Math.cos(officeLatRadians) * Math.sin(dlng / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return radius * c;
}

/**
 * Convert a given date-time to a human-readable "X time ago" format.
 *
 * @param {string|Date} dateTime - The date-time to convert.
 * @returns {string} The human-readable format.
 */
export function timeAgo(dateTime) {
  if (!(dateTime instanceof Date)) {
    dateTime = new Date(dateTime);
  }

  const now = new Date();
  const timeDifference = now - dateTime;

  if (timeDifference < 1000) {
    return 'Just now';
  } else if (timeDifference < 60000) {
    const seconds = Math.floor(timeDifference / 1000);
    return `${seconds} second${seconds !== 1 ? 's':''} `;
  } else if (timeDifference < 3600000) {
    const minutes = Math.floor(timeDifference / 60000);
    const seconds = Math.floor((timeDifference % 60000) / 1000);
    return `${String(minutes).padStart(2, '0')} min ${String(seconds)
        .padStart(2, '0')} sec `;
  } else if (timeDifference < 86400000) {
    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.floor((timeDifference % 3600000) / 60000);
    return `${String(hours).padStart(2, '0')} hr ${String(minutes)
        .padStart(2, '0')} min `;
  } else if (timeDifference < 2592000000) {
    const days = Math.floor(timeDifference / 86400000);
    const hours = Math.floor((timeDifference % 86400000) / 3600000);
    return `${days} day${days !== 1 ? 's':''} ${hours} hr `;
  } else if (timeDifference < 31536000000) {
    const months = Math.floor(timeDifference / 2592000000);
    return `${months} month${months !== 1 ? 's':''} `;
  } else {
    const years = Math.floor(timeDifference / 31536000000);
    return `${years} year${years !== 1 ? 's':''} `;
  }
}

/**
 * Get last 7 days
 * @returns {*[]}
 */
export function getLast7Days() {
  const today = new Date();
  const last7Days = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    last7Days.push(date);
  }

  return last7Days;
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - The input string to be capitalized.
 * @returns {string} - The input string with the first letter capitalized.
 */
export function ucFirst(str) {
  if (!str) return str; // Handle empty string or null

  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Generates an image URL for a device based on its type, status, and device ID.
 *
 * @param {string} type - The type of the device (e.g., "car", "bike", "truck").
 * @param {string} status - The status of the device (e.g., "moving", "parked", "idle").
 * @param {string} deviceId - The unique identifier of the device.
 * @param {string} output - The expected output.
 * @returns {string} - The generated image URL.
 */
export function getImageUrl(type, status, deviceId, output = '') {
  if (!status) {
    status = 'moving';
  }
  const image = `${ucFirst(type)}${ucFirst(status)}${ucFirst(output)}`;

  return `https://firebasestorage.googleapis.com/v0/b/trackandgo-4a78a.appspot.com/o/${image}.png?alt=media&deviceId=${deviceId}`;
}

export function groupSecondaryEnginePath(history) {
  let groups = [];
  if (isArray(history) && history.length > 0) {
    let previousEngineStatus = null;

    let group = [];
    for (const location of history) {
      const currentEngineStatus = get(location, 'engine_on', false);
      if (previousEngineStatus === currentEngineStatus) {
        group.push(location);
      } else {
        if (group.length > 10) {
          groups.push(group);
        }
        group = [];
      }

      previousEngineStatus = currentEngineStatus;
    }
  }

  return groups;
}

/**
 * Converts milliseconds to a formatted string in the format "Xh Ym" (hours and minutes).
 * @param {number} milliseconds - The input time in milliseconds.
 * @returns {string} The formatted time string in the format "Xh Ym".
 */
export function formatMsToMin(milliseconds) {
  const totalMinutes = Math.floor(milliseconds / (1000 * 60));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let formattedTime = `${hours}h`;
  if (minutes > 0) {
    if (formattedTime !== '') {
      formattedTime += ' ';
    }
    formattedTime += `${minutes}m`;
  }

  return formattedTime;
}

export function getDeviceLastLocation(device) {
  const lastLocation = get(device, 'terminal.last_location', null);
  if (lastLocation) {
    return {
      'lat': get(lastLocation, 'latitude', 0),
      'lng': get(lastLocation, 'longitude', 0),
      'bearing': get(lastLocation, 'course', 0),
      'speed': get(lastLocation, 'speed', 0),
    };
  }
  return false;
}
