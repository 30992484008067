import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MdOutlineCarCrash} from 'react-icons/md';

import {get} from '../helpers';
import axios from '../axios';
import {
  updateLoading,
} from '../redux/slices/generalSlice';
import {
  removeAlarm,
  updateSafeParkingStatus,
} from '../redux/slices/deviceSlice';

export default function Alarm() {
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const alarms = useSelector((state) => state.device.alarms);

  useEffect(() => {
    if (alarms.length > 0) {
      try {
        audioRef.current.play();
      } catch (e) {

      }
    }
  }, [alarms]);

  if (alarms.length <= 0) {
    return null;
  }
  const details = get(alarms, '0', {});
  const date = new Date(details.timestamp).toLocaleString();
  let type = '';
  let message = '';

  switch (get(details, 'alarm_type')) {
    case 'safe_parking':
      type = 'Safe parking alert!';
      message = get(details, 'message', '');
      break;
    case 'power_cut':
      type = 'GPS disconnect alert!';
      message = get(details, 'message', '');
      break;
    case 'power_off':
      type = 'GPS turned off alert!';
      message = get(details, 'message', '');
      break;
    case 'offline':
      type = 'GPS offline alert!';
      message = get(details, 'message', '');
      break;
    default:
      break;
  }

  const stopAlarm = () => {
    dispatch(updateLoading(true));
    axios.post(`/vehicle/stop-alarm`, {
      id: get(details, 'id'),
      vehicleId: get(details, 'vehicle_id'),
      alarmType: get(details, 'alarm_type'),
    })
        .then((response) => {
          if (get(response, 'success')) {
            if (get(details, 'alarm_type') === 'safe_parking') {
              dispatch(updateSafeParkingStatus({
                imei: get(details, 'vehicle_id'),
                safe_parking_location: null,
              }));
            }
            dispatch(removeAlarm(get(details, 'id')));
          }
          dispatch(updateLoading(false));
        })
        .catch(() => {
          dispatch(updateLoading(false));
        });
  };

  return (
      <div
          className="bg-white fixed top-0 bottom-0 right-0 left-0 z-[101] bg-opacity-80 backdrop-blur-sm flex items-center justify-center flex-col">
        <audio ref={audioRef} autoPlay={true}
               src="https://firebasestorage.googleapis.com/v0/b/trackandgo-4a78a.appspot.com/o/alarm.mp3?alt=media"
               loop
        />

        <div className="px-5  flex items-center justify-center flex-col">
          <div>
            <MdOutlineCarCrash color="#FF0004" size={100}/>
          </div>
          <p className="text-neutral-800 text-base uppercase mt-2">{type}</p>
          <p className="text-blue-950 text-xl mt-2 font-bold text-center">{message}</p>
          <p className="text-neutral-800 text-normal mt-2 font-bold text-center">{date}</p>

          <button
              className="mt-10 bg-blue-950 py-2 px-4 rounded text-white font-semibold"
              onClick={stopAlarm}
          >
            No, it's me
          </button>
        </div>
      </div>
  );
}
