import React from 'react';
import {Chart} from 'react-google-charts';

import {get} from '../../helpers';

export default function TravellingHours({reports}) {
  const reportData = [
    ['Date', 'Total Hours', 'Moving Hours', 'Idle Hours'],
  ];
  for (const report of reports) {
    let points = [];
    points.push(new Date(report.date).toLocaleDateString());
    let engineHours = get(report, 'primary_engine_hours', 0);
    points.push(engineHours / (1000 * 60 * 60));

    let movingHours = get(report, 'moving_hours', 0);
    if (movingHours > engineHours) {
      movingHours = engineHours;
    }
    points.push(movingHours / (1000 * 60 * 60));

    let idleHours = engineHours - movingHours;
    if (idleHours < 0) {
      points.push(0);
    } else {
      points.push(idleHours / (1000 * 60 * 60));
    }

    reportData.push(points);
  }

  return (
      <div>
        <div className="text-black text-xl font-bold leading-7 mb-5">
          Daily Activity Report
        </div>
        <div
            className="p-5 bg-white border border-neutral-100 shadow rounded-xl">
          <Chart
              chartType="LineChart"
              width="100%"
              height="500px"
              data={reportData}
              options={{
                curveType: 'function',
                legend: {position: 'bottom'},
              }}
          />
        </div>
      </div>
  );
}
