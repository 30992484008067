import {createSlice} from '@reduxjs/toolkit';
import {get} from '../../helpers';

export const generalSlice = createSlice({
  name: 'general', initialState: {
    userDevice: 'desktop',
    infobox: false,
    satelliteView: false,
    trafficView: false,
    maxZoom: 18,
    locationSharingPopup: false,
    showDrawerMenu: false,
    playbackPopup: false,
    vehicleInfoPopup: false,
    vehicleTransactionPopup: false,
    vehicleRemainderPopup: false,
    playbackDate: null,
    showingPlayback: false,
    playbackProgress: 0,
    playbackPlaying: false,
    playbackPlayingCompleted: false,
    playbackSpeed: 1,
    loading: false,
    creatingGeoFence: false,
    playbackPath: [],
    playbackStops: [],
    polylinePath: [],
  }, reducers: {
    updateUserDevice: (state, action) => {
      state.userDevice = action.payload;
    }, updateCreatingGeoFence: (state, action) => {
      state.creatingGeoFence = action.payload;
    }, updatePlaybackPopup: (state, action) => {
      state.playbackPopup = action.payload;
    }, updatePlaybackDate: (state, action) => {
      state.playbackDate = action.payload;
    }, updateShowingPlayback: (state, action) => {
      state.showingPlayback = action.payload;
    }, updatePlaybackHistory: (state, action) => {
      state.playbackPath = get(action, 'payload.path', []);
      state.playbackStops = get(action, 'payload.stops', []);
    }, updatePolylinePath: (state, action) => {
      state.polylinePath = action.payload;
    }, updateLoading: (state, action) => {
      state.loading = action.payload;
    }, updateLocationSharingPopup: (state, action) => {
      state.locationSharingPopup = action.payload;
    }, updatePlaybackProgress: (state, action) => {
      state.playbackProgress = action.payload;
    }, updatePlaybackPlaying: (state, action) => {
      state.playbackPlaying = action.payload;
    }, updatePlaybackCompleted: (state, action) => {
      state.playbackPlayingCompleted = action.payload;
    }, updatePlaybackSpeed: (state, action) => {
      state.playbackSpeed = action.payload;
    }, updateVehicleInfoPopup: (state, action) => {
      state.vehicleInfoPopup = action.payload;
    }, updateVehicleTransactionPopup: (state, action) => {
      state.vehicleTransactionPopup = action.payload;
    }, updateVehicleRemainderPopup: (state, action) => {
      state.vehicleRemainderPopup = action.payload;
    }, updateShowDrawerMenu: (state, action) => {
      state.showDrawerMenu = action.payload;
    }, updateInfobox: (state, action) => {
      state.infobox = action.payload;
    }, updateSatelliteView: (state, action) => {
      state.satelliteView = action.payload;
    }, updateTrafficView: (state, action) => {
      state.trafficView = action.payload;
    }, updateMaxZoom: (state, action) => {
      state.maxZoom = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateMaxZoom,
  updateInfobox,
  updateSatelliteView,
  updateTrafficView,
  updateUserDevice,
  updatePlaybackPopup,
  updatePlaybackDate,
  updateShowingPlayback,
  updatePlaybackHistory,
  updateLoading,
  updatePlaybackProgress,
  updatePlaybackPlaying,
  updatePlaybackCompleted,
  updatePlaybackSpeed,
  updateVehicleInfoPopup,
  updateLocationSharingPopup,
  updateShowDrawerMenu,
  updateVehicleTransactionPopup,
  updateVehicleRemainderPopup,
  updateCreatingGeoFence,
  updatePolylinePath
} = generalSlice.actions;

export default generalSlice.reducer;
