import {AiOutlineClose} from 'react-icons/ai';
import {useEffect, useRef} from 'react';

export default function Popup({show, title, children, onClose}) {
  const drawerMenuRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerMenuRef.current &&
          !drawerMenuRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
      <div
          className={`c-popup bg-black bg-opacity-40 fixed top-0 bottom-0 right-0 left-0 z-[101] flex justify-center items-center overflow-y-auto ${show ? 'block':'hidden'}`}
      >
        <div className="relative w-full max-w-md px-4 h-full max-h-full py-4 flex items-center justify-center mx-auto">
          <div
              className="bg-white rounded-lg shadow relative p-3 max-h-full overflow-hidden my-auto w-full"
              ref={drawerMenuRef}>
            <button className="absolute top-3 right-3" onClick={onClose}>
              <AiOutlineClose color="#7F7F7F" size={24}/>
            </button>
            {title && (
                <div className="text-blue-950 font-bold mb-2 text-center">
                  {title}
                </div>
            )}
            <div className="overflow-y-auto max-h-[80vh]">
              {children}
            </div>
          </div>
        </div>
      </div>
  );
}
