import React, {useState} from 'react';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';

const colors = [
  'red',
  'green',
  'blue',
  'yellow',
  'orange',
  'purple',
  'pink',
  'teal',
  'cyan',
  'magenta',
  'brown',
  'black',
  'white',
  'gray',
  'lime',
  'olive',
  'navy',
  'maroon',
  'indigo',
  'aqua',
  'fuchsia',
  'skyblue',
];

export default function Accordion({title, openDefault, children, accordionId}) {
  const [open, setOpen] = useState(openDefault);
  return (
      <div className="accordion">
        <div
            className={`title px-3 py-4 border-y border-neutral-200 flex justify-between items-center cursor-pointer ${open ? 'open': 'close'}`}
            onClick={() => {
              setOpen(!open);
            }}
        >
          <div className="flex items-center">
            <span className="w-4 h-4 rounded-full mr-4"
                  style={{background: colors[accordionId]}}></span>
            <span className="text-zinc-500 text-sm font-normal">{title}</span>
          </div>
          <div>
            {open ?
                <IoIosArrowUp size={24} color="#7F7F7F"/>:
                <IoIosArrowDown size={24} color="#001C6F"/>
            }
          </div>
        </div>
        <div className={`${open ? 'block':'hidden'} p-2`}>
          {children}
        </div>
      </div>
  );
}
