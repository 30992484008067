import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import DataTable from 'react-data-table-component';
import {BsFillPencilFill, BsFillTrashFill} from 'react-icons/bs';

import {updateLoading} from '../redux/slices/generalSlice';
import {remainderCategories} from '../constants';
import axios from '../axios';
import {get} from '../helpers';

export default function ListRemaindersTabContent({onEdit}) {
  const loading = useSelector((state) => state.general.loading);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const fetchRemainders = useCallback(async (
      page, perPage, sortColumn = 'none', sortDirection = 'none') => {
    dispatch(updateLoading(true));
    try {
      const response = await axios.get(
          `/vehicle/${selectedDevice}/remainders?page=${page}&per_page=${perPage}&sort_column=${sortColumn}&sort_direction=${sortDirection}`);
      const vehicles = get(response, 'remainders', []);
      const count = get(response, 'count', 0);
      // console.log(response);
      setData(vehicles);
      setTotalRows(count);
    } catch (e) {
      console.log(e.message);
    }
    dispatch(updateLoading(false));
  }, [dispatch, selectedDevice]);

  useEffect(() => {
    fetchRemainders(page, 10, 'none', 'none').then(r => {
    });
  }, [fetchRemainders, page]);

  function deleteRemainder(remainderId, vehicleId, index) {
    dispatch(updateLoading(true));
    axios
        .delete(`/vehicle/${vehicleId}/delete-remainder/${remainderId}`)
        .then((response) => {
          if (response?.success) {
            setData((allRemainders) => {
              let previousRemainders = [...allRemainders];
              previousRemainders.splice(index, 1);
              return previousRemainders;
            });
            toast.success('Remainder deleted successfully!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          dispatch(updateLoading(false));
          toast.error('Unable to delete the remainder!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  }

  const columns = [
    {
      name: 'Due date',
      selector: row => new Date(row.due_date).toLocaleDateString(),
    },
    {
      name: 'Category',
      selector: row => {
        const category = remainderCategories.find((categoryDetails) => {
          return categoryDetails.name === row.category;
        });
        if (category) {
          return category.label;
        }
        return 'Other';
      },
    },
    {
      name: '',
      selector: (row, index) => {
        return <div className="flex">
          <button
              onClick={() => {
                onEdit(row)
              }}
              className="bg-neutral-100 border border-neutral-300 p-2 rounded mr-2">
            <BsFillPencilFill size={18} color="#7F7F7F"/>
          </button>
          <button
              onClick={() => {
                deleteRemainder(row.id, row.vehicle_id, index);
              }}
              className="bg-red-100 border border-red-300 p-2 rounded mr-2">
            <BsFillTrashFill color="#FF0004" size={18}/>
          </button>
        </div>;
      },
    },
  ];

  return (
      <DataTable
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          columns={columns}
          data={data}
          noDataComponent="No remainders found!"
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={(page) => {
            setPage(page);
          }}
      />

  );
}
