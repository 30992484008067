import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {MdArrowBack} from 'react-icons/md';
import {BsShareFill, BsFillTrashFill} from 'react-icons/bs';
import {TbMapPinShare} from 'react-icons/tb';
import {confirmAlert} from 'react-confirm-alert';
import {toast} from 'react-toastify';

import DesktopHeader from '../components/DesktopHeader';
import axios from '../axios';
import Loading from '../components/Loading';
import {get} from '../helpers';
import LocationSharingPopup from '../components/LocationSharingPopup';
import {
  updateLoading,
  updateLocationSharingPopup,
} from '../redux/slices/generalSlice';

export default function VehicleSharing() {
  const {vehicleId} = useParams();
  const navigate = useNavigate();
  const locationSharingPopup = useSelector((state) => state.general.locationSharingPopup);
  const loading = useSelector((state) => state.general.loading);
  const [locations, setLocations] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(true));
    axios
        .get(`/user/vehicle/sharing/${vehicleId}`)
        .then((response) => {
          if (response?.success) {
            const sharedLocations = response?.locations ?? [];
            if (sharedLocations.length > 0) {
              setLocations(sharedLocations);
            }
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          dispatch(updateLoading(false));
        });
  }, [dispatch, vehicleId]);

  const deleteSharedLocation = (locationId, index) => {
    dispatch(updateLoading(true));
    axios
        .delete(`/user/vehicle/sharing/delete/${locationId}`)
        .then((response) => {
          if (response?.success) {
            setLocations((allLocations) => {
              let previousLocations = [...allLocations];
              previousLocations.splice(index, 1);
              return previousLocations;
            });
            toast.success("Location shared link deleted successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          dispatch(updateLoading(false));
          toast.error("Unable to delete the shared location!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  };

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  navigate(-1);
                }}
            >
              <MdArrowBack size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Location Sharing
              </div>
            </div>
            <button
                className="cursor-pointer flex items-center px-2 py-4 w-12 z-10"
                onClick={() => {
                  dispatch(updateLocationSharingPopup(true));
                }}
            >
              <TbMapPinShare size={24} color="#001C6F"/>
            </button>
          </div>
        </div>
        <div className="flex-1 relative p-5 table-listing">
          <div
              className="text-blue-950 text-xl font-bold md:mb-5 md:block hidden">
            <span>Location Sharing</span>
            <span
                className="ml-5 bg-blue-950 rounded-2xl text-center text-white text-base font-normal text-sm py-2 px-5 mx-2 font-bold">
              <button
                  onClick={() => {
                    dispatch(updateLocationSharingPopup(true));
                  }}
              >
                Add New
              </button>
            </span>
          </div>
          <div>
            <table
                className="w-full bg-white rounded-lg shadow border-collapse">
              <thead>
              <tr>
                <th>
                  <div className="text-zinc-500 text-base font-bold">
                    Share Type
                  </div>
                </th>
                <th>
                  <div className="text-zinc-500 text-base font-bold">
                    URL
                  </div>
                </th>
                <th>
                  <div className="text-zinc-500 text-base font-bold">
                    Expires in
                  </div>
                </th>
                <th>
                </th>
              </tr>
              </thead>
              {locations.length > 0 && (
                  <tbody>
                  {locations.map((location, index) => {
                    let expiredDate = new Date(
                        get(location, 'expires_at'));

                    return (
                        <tr key={`device-details-${index}`}>
                          <td>
                            <div className="text-zinc-500 text-sm font-normal">
                              {get(location, 'share_type', 'current') ===
                              'current' ? 'Current':'Live'} Location
                            </div>
                          </td>
                          <td>
                            <div className="text-zinc-500 text-sm font-normal">
                              <a target="_blank" rel="noreferrer"
                                 href={`${process.env.REACT_APP_MAIN_URL}/public/share/${get(
                                     location, 'token', '#')}`}>
                                {process.env.REACT_APP_MAIN_URL}/public/share/{get(
                                  location, 'token', '#')}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="text-zinc-500 text-sm font-normal">
                              {expiredDate.toLocaleDateString()}
                            </div>
                          </td>
                          <td>
                            <div>
                              <button
                                  className="p-2"
                              >
                                <BsShareFill color="#7F7F7F" size={18}/>
                              </button>
                              <button
                                  className="p-2"
                                  onClick={() => {
                                    confirmAlert({
                                      title: 'Confirm to delete',
                                      message: 'Are you sure want to delete the location sharing?',
                                      buttons: [
                                        {
                                          label: 'Yes',
                                          onClick: () => {
                                            deleteSharedLocation(location.id, index);
                                          },
                                        },
                                        {
                                          label: 'No',
                                          onClick: () => {},
                                        },
                                      ],
                                    });
                                  }}
                              >
                                <BsFillTrashFill color="#FF0004" size={18}/>
                              </button>
                            </div>
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
              )}
              {locations.length <= 0 && (
                  <tbody>
                  <tr>
                    <td colSpan={4}>
                      <div>
                        No shared locations found!
                      </div>
                    </td>
                  </tr>
                  </tbody>
              )}
            </table>
          </div>
        </div>
        <LocationSharingPopup
            show={locationSharingPopup}
            selectedDevice={vehicleId}
            updateSharing={(newLocation) => {
              setLocations((allLocations) => {
                let previousLocations = [...allLocations];
                previousLocations.push(newLocation);
                return previousLocations;
              });
            }}
        />
        {loading && <Loading/>}
      </div>
  );
}
