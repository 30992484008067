import {useCallback, useEffect, useState} from 'react';
import {formatMsToMin} from '../helpers';

export default function SecondaryEngineRanHours(
    {
      lastOnTime = null,
      totalRanHours = 0,
    },
) {
  const [ranHours, setRanHours] = useState(totalRanHours);

  const calculateRanHours = useCallback(
      () => {
        if (lastOnTime !== null) {
          const currentTime = new Date();
          const lastOnDateTime = new Date(lastOnTime);
          const newRanHours = totalRanHours + (currentTime - lastOnDateTime);
          setRanHours(newRanHours);
        } else {
          setRanHours(totalRanHours);
        }
      },
      [lastOnTime, totalRanHours],
  );

  useEffect(() => {
    // Update every second
    const intervalId = setInterval(() => {
      calculateRanHours()
    }, 60000);

    // Return a function to clear the interval when needed
    return () => clearInterval(intervalId);
  }, [calculateRanHours]);

  return formatMsToMin(ranHours);
}
