import React from 'react';
import {LiaCarBatterySolid} from 'react-icons/lia';
import {isNumber} from '../helpers';

export default function GpsExternalBatteryStatus({status}) {
  if (!status) {
    return null;
  }

  return (
      <div className="flex justify-between items-center">
        <span>
          <LiaCarBatterySolid color="#099F00" size={18}/>
        </span>
        <span className="text-[11px] text-neutral-500 font-bold mt-1">
          {isNumber(status)?parseFloat(status).toFixed(1):status}V
        </span>
      </div>
  );
}
