import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import DatePicker from 'react-datepicker';

import Popup from './Popup';
import {
  updateLoading,
  updateVehicleTransactionPopup,
} from '../redux/slices/generalSlice';
import {categories} from '../constants';
import axios from '../axios';
import {get, set, toFloat} from '../helpers';
import ListTransactionsTabContent from './ListTransactionsTabContent';

const initialState = {
  type: 'expense',
  amount: 0,
  date: new Date().toISOString(),
  category: 'ex_fuel',
  note: '',
};

export default function AddTransactionPopup() {
  const [details, setDetails] = useState(initialState);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const [formErrors, setFormErrors] = useState({});
  const [tab, setTab] = useState('add');
  const [task, setTask] = useState('add');

  const dispatch = useDispatch();
  const closeTransactionPopup = () => {
    dispatch(updateVehicleTransactionPopup(false));
  };

  const saveVehicleTransaction = (e) => {
    e.preventDefault();
    const data = {...details, vehicle_id: selectedDevice};
    dispatch(updateLoading(true));
    axios.post(`/vehicle/add-transaction`, data)
        .then((response) => {
          if (get(response, 'success')) {
            let message;
            if (task === 'add') {
              message = 'Transaction created successfully!';
              setDetails(initialState);
            } else {
              message = 'Transaction updated successfully!';
            }
            toast.success(message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          } else if (get(response, 'errors')) {
            setFormErrors(response?.errors);
          }
          dispatch(updateLoading(false));
        })
        .catch((e) => {
          console.log(e.message);
          dispatch(updateLoading(false));
          toast.error('Transaction create failed!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  };

  const ErrorMessage = ({message}) => {
    if (!message || message.length <= 0) {
      return null;
    }
    return (<div className="text-orange text-xs mt-1">{message}</div>);
  };

  const updateData = (column, value) => {
    setDetails((ieData) => {
      const newIeData = {...ieData};
      set(newIeData, column, value);
      return newIeData;
    });
  };

  const filteredCategories = categories.filter((category) => {
    if (get(details, 'type') === 'income') {
      return category.name.startsWith('in_');
    } else if (get(details, 'type') === 'expense') {
      return category.name.startsWith('ex_');
    }

    return false;
  });

  return (
      <Popup title="Transaction" onClose={closeTransactionPopup}
             show={true}>
        <div className="w-full">
          <div className="flex mt-2">
            <button
                type="button"
                onClick={() => {
                  setTab('add');
                }}
                className={`${tab === 'add' ?
                    'text-blue-950 font-bold border-b-2 border-blue-950':
                    'text-zinc-500 border-b border-neutral-200'} flex-1 py-2`}>
              {task === 'add' ? 'Add New':'Edit Transaction'}
            </button>
            <button
                type="button"
                onClick={() => {
                  setTask('add');
                  setDetails(initialState);
                  setTab('list');
                }}
                className={`${tab === 'list' ?
                    'text-blue-950 font-bold border-b-2 border-blue-950':
                    'text-zinc-500 border-b border-neutral-200'} flex-1 py-2`}>
              View All
            </button>
          </div>
        </div>
        {tab === 'add' && (
        <form onSubmit={saveVehicleTransaction}>
          <div className="w-full">
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Date *
              </div>
              <div className="">
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={new Date(get(details, 'date'))}
                    onChange={(date) => {
                      updateData('date', date.toISOString());
                    }}
                />
              </div>
              <ErrorMessage message={get(formErrors, 'date', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Type *
              </div>
              <div className="w-full flex">
                <button
                    type="button"
                    onClick={() => {
                      updateData('type', 'income');
                      updateData('category', '');
                    }}
                    className={`flex-1 ${get(details, 'type') === 'income' ?
                        'bg-green-400 text-white':
                        'bg-neutral-200 text-zinc-500'} rounded text-center text-base font-normal py-2 px-5 mx-2 font-bold`}
                >
                  Income
                </button>
                <button
                    type="button"
                    onClick={() => {
                      updateData('type', 'expense');
                      updateData('category', '');
                    }}
                    className={`flex-1 ${get(details, 'type') === 'expense' ?
                        'bg-red-400 text-white':
                        'bg-neutral-200 text-zinc-500'} rounded text-center text-base font-normal py-2 px-5 mx-2 font-bold`}
                >
                  Expense
                </button>
              </div>
              <ErrorMessage message={get(formErrors, 'type', null)}/>
            </div>
            <div className="mt-4">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Category *
              </div>
              <div>
                <select
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    value={get(details, 'category')}
                    required
                    onChange={(e) => {
                      updateData('category', e.target.value);
                    }}
                >
                  <option value=""> --- SELECT ---</option>
                  {
                    filteredCategories.map((category, index) => {
                      return (<option
                          value={category.name}
                          key={`ie-category-option-${index}`}>
                        {category.label}
                      </option>);
                    })
                  }
                </select>
              </div>
              <ErrorMessage message={get(formErrors, 'category', null)}/>
            </div>
          </div>
          <div className="mt-4">
            <div
                className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
              Amount *
            </div>
            <div>
              <input
                  className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                  placeholder="Example: 2550.50"
                  value={get(details, 'amount')}
                  required
                  type="number"
                  onChange={(e) => {
                    const amount = toFloat(e.target.value, 0);
                    updateData('amount', amount < 0 ? 0:amount);
                  }}
              />
            </div>
            <ErrorMessage message={get(formErrors, 'amount', null)}/>
          </div>
          <div className="mt-4">
            <div
                className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
              Note
            </div>
            <div>
              <input
                  className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                  placeholder="Example: Petrol"
                  value={get(details, 'note')}
                  type="text"
                  onChange={(e) => {
                    updateData('note', e.target.value);
                  }}
              />
            </div>
            <ErrorMessage message={get(formErrors, 'date', null)}/>
          </div>
          <div className="mt-5 mb-2 text-center">
            <button
                className="bg-blue-950 rounded-2xl text-center text-white text-base font-normal py-2 px-5 mx-2 font-bold"
                type="submit"
            >
              Save
            </button>
            <button
                className="bg-neutral-200 rounded-2xl text-center text-zinc-500 text-base font-normal py-2 px-5 mx-2 font-bold"
                onClick={closeTransactionPopup}
            >
              Close
            </button>
          </div>
        </form>
        )}
        {tab === 'list' && (
            <div className="mt-4">
              <ListTransactionsTabContent onEdit={(transactionDetails) => {
                setTask('edit');
                setDetails(transactionDetails);
                setTab('add');
              }}/>
            </div>
        )}
      </Popup>
  );
}
