import {MdOutlineMenu} from 'react-icons/md';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import { toast } from 'react-toastify';

import DesktopHeader from '../components/DesktopHeader';
import {updateShowDrawerMenu} from '../redux/slices/generalSlice';
import Loading from '../components/Loading';
import Drawer from '../components/Drawer';
import axios from '../axios';
import {get} from '../helpers';

export default function EditProfile() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordField, setPasswordField] = useState('password');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordField, setNewPasswordField] = useState('password');

  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordField, setConfirmNewPasswordField] = useState(
      'password');

  const [error, setError] = useState('');

  const changePassword = (e) => {
    e.preventDefault();
    if (password.length < 5) {
      setError('Your current password should have at least 5 characters.');
      return;
    } else if (newPassword.length < 5) {
      setError('Your new password should have at least 5 characters.');
      return;
    } else if (confirmNewPassword.length < 5) {
      setError('Your confirm password should have at least 5 characters.');
      return;
    } else if (newPassword !== confirmNewPassword) {
      setError('Your new password and confirm password should be same.');
      return;
    } else if (newPassword === password) {
      setError('Your current password and new password should not be same.');
      return;
    }

    setLoading(true);
    axios.post('/user/password-change', {
      password, confirmNewPassword, newPassword,
    })
        .then((response) => {
          if (response?.success) {
            setPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            toast.success(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
          setLoading(false);
        })
        .catch((e) => {
          let errorMessage = get(e, 'response.data.message', null);
          console.log(errorMessage);
          setError(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        });
  };

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  dispatch(updateShowDrawerMenu(true));
                }}
            >
              <MdOutlineMenu size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Edit Profile
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 relative p-6">
          <div className="md:max-w-md w-full">
            <form onSubmit={changePassword}>
              <div className="w-full">
                <div className="mt-4">
                  <div
                      className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                    Current Password
                  </div>
                  <div className="relative">
                    <input
                        className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                        placeholder="Example: #p@ssw0rd#"
                        value={password}
                        required
                        type={passwordField}
                        onChange={(e) => {
                          setError(null);
                          setPassword(e.target.value);
                        }}
                    />
                    <span
                        className="absolute right-4 top-4"
                        onClick={() => {
                          setPasswordField((oldValue) => {
                            return oldValue === 'text' ? 'password':'text';
                          });
                        }}
                    >
                    {passwordField === 'password' ?
                        <AiOutlineEye color="#000000" size={24}/>:
                        <AiOutlineEyeInvisible color="#000000" size={24}/>}
                  </span>
                  </div>
                </div>

                <div className="mt-4">
                  <div
                      className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                    New Password
                  </div>
                  <div className="relative">
                    <input
                        className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                        placeholder="Example: #p@ssw0rd#"
                        value={newPassword}
                        required
                        type={newPasswordField}
                        onChange={(e) => {
                          setError(null);
                          setNewPassword(e.target.value);
                        }}
                    />
                    <span
                        className="absolute right-4 top-4"
                        onClick={() => {
                          setNewPasswordField((oldValue) => {
                            return oldValue === 'text' ? 'password':'text';
                          });
                        }}
                    >
                    {newPasswordField === 'password' ?
                        <AiOutlineEye color="#000000" size={24}/>:
                        <AiOutlineEyeInvisible color="#000000" size={24}/>}
                  </span>
                  </div>
                </div>

                <div className="mt-4">
                  <div
                      className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                    Confirm New Password
                  </div>
                  <div className="relative">
                    <input
                        className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                        placeholder="Example: #p@ssw0rd#"
                        value={confirmNewPassword}
                        required
                        type={confirmNewPasswordField}
                        onChange={(e) => {
                          setError(null);
                          setConfirmNewPassword(e.target.value);
                        }}
                    />
                    <span
                        className="absolute right-4 top-4"
                        onClick={() => {
                          setConfirmNewPasswordField((oldValue) => {
                            return oldValue === 'text' ? 'password':'text';
                          });
                        }}
                    >
                    {confirmNewPasswordField === 'password' ?
                        <AiOutlineEye color="#000000" size={24}/>:
                        <AiOutlineEyeInvisible color="#000000" size={24}/>}
                  </span>
                  </div>
                </div>

                <div className="mt-12 text-center">
                  <button
                      type="submit" disabled={loading}
                      className=" py-2 px-5 bg-blue-950 rounded-3xl text-white text-lg font-bold w-full">
                    Change Password
                  </button>
                </div>
                {error && <div className="text-red-500 text-center mt-2">
                  {error}
                </div>}

              </div>
            </form>
          </div>
        </div>
        {loading && <Loading/>}
        <Drawer/>
      </div>
  );
}
