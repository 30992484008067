import {useCallback, memo, useEffect, useRef, useMemo, useState} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline, Circle, InfoBox,
  TrafficLayer,
} from '@react-google-maps/api';
import {useDispatch, useSelector} from 'react-redux';

import {
  selectSingleDevice, setMapZoom, setMapBounds, hideMapOnlyView,
} from '../redux/slices/deviceSlice';
import {
  getImageUrl,
  groupSecondaryEnginePath,
  isArray,
  get, getDeviceLastLocation,
} from '../helpers';

const center = {
  lat: 11.0053,
  lng: 77.5609,
};

function GoogleMaps() {
  const {isLoaded} = useJsApiLoader({
    id: 'devices-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });
  const [deviceLocked, setDeviceLocked] = useState(false);
  const playbackProgress = useSelector((state) => state.general.playbackProgress);
  const allDevices = useSelector((state) => state.device.list);
  const mapZoom = useSelector((state) => state.device.mapZoom);
  const mapBounds = useSelector((state) => state.device.mapBounds);
  const locations = useSelector((state) => state.device.locations);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const showingPlayback = useSelector((state) => state.general.showingPlayback);
  const infobox = useSelector((state) => state.general.infobox);
  const satelliteView = useSelector((state) => state.general.satelliteView);
  const trafficView = useSelector((state) => state.general.trafficView);
  const maxZoom = useSelector((state) => state.general.maxZoom);
  const playbackPath = useSelector((state) => state.general.playbackPath);
  const playbackStops = useSelector((state) => state.general.playbackStops);
  const polylinePath = useSelector((state) => state.general.polylinePath);
  const polyLine = useSelector((state) => state.device.polyLine);

  const dispatch = useDispatch();
  const mapRef = useRef(null);

  const allMarkers = useMemo(() => {
    const markers = [];
    allDevices.forEach((device) => {
      const lastLocation = getDeviceLastLocation(device);
      if (lastLocation) {
        markers.push(lastLocation);
      }
    });
    return markers;
  }, [allDevices]);

  useEffect(() => {
    if (mapRef.current && mapBounds) {
      const bounds = new window.google.maps.LatLngBounds();
      if (polyLine) {
        polylinePath.forEach((position) => {
          bounds.extend(position);
        });
        mapRef.current.fitBounds(bounds);
      } else if (showingPlayback) {
        playbackPath.forEach((position) => {
          bounds.extend(position);
        });
        mapRef.current.fitBounds(bounds);
      } else if (mapBounds.length <= 0) {
        setDeviceLocked(false);
        if (allMarkers.length > 0) {
          allMarkers.forEach((position) => {
            bounds.extend(position);
          });
          for (const deviceId in locations) {
            if (locations.hasOwnProperty(deviceId)) {
              const deviceLocations = get(locations, deviceId, []);
              if (isArray(deviceLocations) && deviceLocations.length > 0) {
                deviceLocations.forEach((position) => {
                  if (position) {
                    bounds.extend(position);
                  }
                });
              }
            }
          }
          mapRef.current.fitBounds(bounds);
        }
      } else {
        if (!deviceLocked) {
          mapBounds.forEach((position) => {
            bounds.extend(position);
          });
          if (selectedDevice) {
            const deviceLocations = get(locations, selectedDevice, []);
            if (isArray(deviceLocations) && deviceLocations.length > 0) {
              deviceLocations.forEach((position) => {
                if (position) {
                  bounds.extend(position);
                }
              });
            }
          }
          mapRef.current.fitBounds(bounds);
          setDeviceLocked(true);
        }
      }
    }
  }, [allMarkers, locations, mapBounds, playbackPath, selectedDevice, showingPlayback, polylinePath, polyLine, deviceLocked]);

  const onLoad = useCallback(function callback(newMap) {
    mapRef.current = newMap;
    dispatch(setMapBounds(allMarkers));
  }, [allMarkers, dispatch]);

  const onUnmount = useCallback(function callback(map) {
    map.current = null;
  }, []);

  if (!isLoaded) {
    return null;
  }

  const selectedDeviceDetails = allDevices.find((obj) => {
    return obj.imei === selectedDevice;
  });

  const secondaryEnginePaths = groupSecondaryEnginePath(playbackPath);

  return (
      <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={mapZoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            disableDefaultUI: true,
            maxZoom: maxZoom,
          }}
          mapTypeId={satelliteView ? 'hybrid':'roadmap'}
      >
        {trafficView && <TrafficLayer autoUpdate/>}
        {(!showingPlayback && !polyLine) && (
            <>
              {
                allDevices.map((device, index) => {
                  const lastLocation = getDeviceLastLocation(device);
                  if (!lastLocation) {
                    return null;
                  }
                  const deviceLocations = get(locations, device.imei, []);
                  const safeParkedLocationInfo = get(device,
                      'safe_parking_location', null);
                  const safeParkingEnabled = safeParkedLocationInfo !== null;
                  let safeParkedLocation;
                  if (safeParkingEnabled && safeParkedLocationInfo) {
                    safeParkedLocation = {
                      lat: safeParkedLocationInfo.latitude,
                      lng: safeParkedLocationInfo.longitude,
                    };
                  }
                  return (
                      <div key={`map-maker-recent-location-${index}`}>
                        {(
                            isArray(deviceLocations) && deviceLocations.length >
                            0
                        ) && (
                            <Polyline
                                path={deviceLocations}
                                options={{
                                  strokeColor: '#FF2AD0',
                                  strokeWeight: 3,
                                  strokeOpacity: 0.6,
                                  defaultVisible: true,
                                }}
                            />
                        )}
                        <Marker
                            key={`map-maker-recent-location-${index}`}
                            position={{
                              lat: lastLocation.lat,
                              lng: lastLocation.lng,
                            }}
                            draggable={false}
                            icon={{
                              url: getImageUrl(
                                  get(device, 'type', 'car'),
                                  device.terminal.status,
                                  device.imei,
                              ),
                              scaledSize: new window.google.maps.Size(60, 60),
                              anchor: new window.google.maps.Point(30, 30),
                            }}
                            onClick={() => {
                              dispatch(setMapBounds([lastLocation]));
                              dispatch(setMapZoom(14));
                              dispatch(selectSingleDevice(device.imei));
                              dispatch(hideMapOnlyView());
                            }}
                        >
                          {infobox && <InfoBox
                              options={{
                                pane: 'mapPane',
                                closeBoxURL: ``,
                                boxStyle: {
                                  width: '100px',
                                },
                                pixelOffset: new window.google.maps.Size(-50,
                                    10),
                              }}
                          >
                            <div
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  textAlign: 'center',
                                  backgroundColor: '#ffffff',
                                  color: '#2a2a2a',
                                  padding: 5,
                                  borderRadius: 5,
                                  borderWidth: 1,
                                  borderColor: '#e2e2e2',
                                  overflow: 'hidden',
                                }}
                            >
                              {device.name}
                            </div>
                          </InfoBox>}
                        </Marker>
                        {(safeParkedLocation && safeParkingEnabled) && (
                            <Circle
                                center={safeParkedLocation}
                                radius={1.5}
                                options={{
                                  strokeColor: '#0EB600',
                                  strokeOpacity: 0.4,
                                  strokeWeight: 2,
                                  fillColor: '#0EB600',
                                  fillOpacity: 0.5,
                                }}
                            />
                        )}
                      </div>
                  );
                })
              }
            </>
        )}
        {(showingPlayback && !polyLine) && (
            <>
              <Polyline
                  path={playbackPath}
                  options={{
                    strokeColor: '#03A94A',
                    strokeWeight: 3,
                    strokeOpacity: 0.8,
                    defaultVisible: true,
                  }}
              />
              {secondaryEnginePaths.map((secondaryEnginePath, index) => (
                  <Polyline
                      key={`engine-running-path-${index}`}
                      path={secondaryEnginePath}
                      options={{
                        strokeColor: '#fa2121',
                        strokeWeight: 3,
                        strokeOpacity: 1,
                        defaultVisible: true,
                      }}
                  />
              ))}
              <Polyline
                  path={playbackPath.slice(0, playbackProgress + 1)}
                  options={{
                    strokeColor: '#001C6F',
                    strokeWeight: 3,
                    strokeOpacity: 0.8,
                    defaultVisible: true,
                  }}
              />
              {playbackStops.map((stop, index) => (
                  <Marker
                      key={`stop-marker-${index}`}
                      position={{
                        lat: stop.lat,
                        lng: stop.lng,
                      }}
                      title={stop.id}
                      label={`${index + 1}`}
                  />
              ))}
              <Marker
                  icon={{
                    url: getImageUrl(
                        get(selectedDeviceDetails, 'type', 'car'),
                        'moving',
                        selectedDevice,
                    ),
                    scaledSize: new window.google.maps.Size(60, 60),
                    anchor: new window.google.maps.Point(30, 30),
                  }}
                  id={`marker-${selectedDevice}`}
                  position={playbackPath[playbackProgress]}
              />
            </>
        )}
        {polyLine && (
            <Polyline
                path={polylinePath}
                options={{
                  strokeColor: '#03A94A',
                  strokeWeight: 3,
                  strokeOpacity: 0.8,
                  defaultVisible: true,
                }}
            />
        )}
      </GoogleMap>
  );
}

export default memo(GoogleMaps);
