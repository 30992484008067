import {configureStore} from '@reduxjs/toolkit';
import deviceReducer from './slices/deviceSlice';
import generalReducer from './slices/generalSlice';

export default configureStore({
  reducer: {
    device: deviceReducer,
    general: generalReducer,
  },
});
