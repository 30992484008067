import React from 'react';
import {Chart} from 'react-google-charts';

import { get} from '../../helpers';

export default function TravellingDistance({reports}) {
  const reportData = [
    ['Date', 'Total Distance', 'Over Speed Distance'],
  ];
  for (const report of reports) {
    let points = [];
    points.push(new Date(report.date).toLocaleDateString());
    points.push(get(report, 'distance_traveled', 0));
    points.push(get(report, 'over_speed_distance', 0));

    reportData.push(points);
  }

  return (
      <div>
        <div className="text-black text-xl font-bold leading-7 mb-5">
          Distance Traveled Report
        </div>
        <div
            className="p-5 bg-white border border-neutral-100 shadow rounded-xl">
          <Chart
              chartType="AreaChart"
              width="100%"
              height="500px"
              data={reportData}
              options={{
                curveType: 'function',
                legend: {position: 'bottom'},
              }}
          />
        </div>
      </div>
  );
}
