import React, {useEffect, useRef, useState} from 'react';
import {MdReplay, MdArrowBack, MdOutlineMoreVert} from 'react-icons/md';
import {AiFillLock, AiFillUnlock} from 'react-icons/ai';
import {BsFillPencilFill, BsShareFill} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';

import {
  setMapBounds, setPolyline,
  unselectSingleDevice,
} from '../redux/slices/deviceSlice';
import {
  updatePlaybackCompleted,
  updatePlaybackHistory,
  updatePlaybackPlaying,
  updatePlaybackPopup,
  updatePlaybackProgress,
  updateShowingPlayback,
  updateVehicleTransactionPopup,
  updateVehicleInfoPopup, updateVehicleRemainderPopup,
} from '../redux/slices/generalSlice';
import {enableOrDisableSafeParking} from '../Actions';
import {get} from '../helpers';
import {confirmAlert} from 'react-confirm-alert';

export default function MobileSingularHeader() {
  const showingPlayback = useSelector((state) => state.general.showingPlayback);
  const deviceImei = useSelector((state) => state.device.selectedDevice);
  const allDevices = useSelector((state) => state.device.list);
  const device = allDevices.find((item) => item.imei === deviceImei);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const moreMenuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
        setShowMoreMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!device) {
    return null;
  }
  const safeParking = get(device, 'safe_parking_location', null) !== null;

  return (
      <div
          className="w-full bg-white shadow md:hidden block flex justify-between items-center relative">
        <div className="h-full z-10">
          <button
              className="cursor-pointer w-12 px-2 py-4"
              onClick={() => {
                dispatch(setPolyline(false));
                if (showingPlayback) {
                  dispatch(updateShowingPlayback(false));
                  dispatch(updatePlaybackHistory({
                    path: [],
                    stops: [],
                  }));
                  dispatch(updatePlaybackPlaying(false));
                  dispatch(updatePlaybackCompleted(true));
                  dispatch(updatePlaybackProgress(0));
                } else {
                  dispatch(setMapBounds([]));
                  dispatch(unselectSingleDevice());
                }
              }}
          >
            <MdArrowBack size={24} color="#001C6F"/>
          </button>
        </div>
        {showingPlayback && (
            <div className="flex-1 w-full absolute z-0">
              <div
                  className="text-blue-950 text-base font-bold text-center">Playback
              </div>
            </div>
        )}
        {!showingPlayback && (<>
          <button
              className="cursor-pointer flex flex-1 justify-center flex-col items-center py-2"
              onClick={() => {
                dispatch(setPolyline(false));
                dispatch(updatePlaybackPopup(true));
              }}
          >
            <span>
              <MdReplay size={18} color="#7F7F7F"/>
            </span>
            <span
                className="text-zinc-500 text-xs font-normal mt-1">Playback</span>
          </button>
          <button
              className="cursor-pointer flex flex-1 justify-center flex-col items-center py-2"
              onClick={() => {
                dispatch(setPolyline(false));
                if (safeParking) {
                  enableOrDisableSafeParking(deviceImei, dispatch)
                      .then((response) => {
                      });
                } else {
                  confirmAlert({
                    title: 'Confirm to enable safe parking',
                    message: 'Are you sure to do enable safe parking?',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => {
                          enableOrDisableSafeParking(deviceImei, dispatch)
                              .then((response) => {
                              });
                        },
                      },
                      {
                        label: 'No',
                        onClick: () => {
                        },
                      },
                    ],
                  });
                }
              }}
          >
            <span>
              {safeParking ? (
                  <AiFillLock color="#0EB600" size={24}/>
              ):(
                  <AiFillUnlock color="#7F7F7F" size={24}/>
              )}
            </span>
            <span
                className={`${safeParking ? 'text-green-600 text-bold':
                    'text-zinc-500'} text-xs font-normal mt-1`}>Safe Park</span>
          </button>
          <button
              className="cursor-pointer flex flex-1 justify-center flex-col items-center py-2"
              onClick={() => {
                dispatch(setPolyline(false));
                dispatch(updateVehicleInfoPopup(true));
              }}
          >
            <span>
              <BsFillPencilFill size={18} color="#7F7F7F"/>
            </span>
            <span className="text-zinc-500 text-xs font-normal mt-1">Edit</span>
          </button>
          <button
              className="cursor-pointer flex flex-1 justify-center flex-col items-center py-2"
              onClick={() => {
                dispatch(setPolyline(false));
                navigate(`/share/vehicle/${deviceImei}`);
              }}
          >
            <span>
              <BsShareFill size={18} color="#7F7F7F"/>
            </span>
            <span
                className="text-zinc-500 text-xs font-normal mt-1">Share</span>
          </button>
          <button
              className="cursor-pointer py-2 px-2"
              onClick={() => {
                setShowMoreMenu(true);
              }}
          >
            <MdOutlineMoreVert size={24} color="#7F7F7F"/>
          </button>
        </>)}
        {showMoreMenu && (
            <div
                className="absolute top-12 right-2 bg-white rounded z-[101] border border-neutral-100 shadow p-2"
                ref={moreMenuRef}
            >
              <NavLink
                  className="text-zinc-500 text-base font-normal p-2 block w-full text-left"
                  to={`/report/${deviceImei}`}
              >
                Report
              </NavLink>
              <button
                  onClick={() => {
                    setShowMoreMenu(false);
                    dispatch(setPolyline(false));
                    dispatch(updateVehicleTransactionPopup(true));
                  }}
                  className="text-zinc-500 text-base font-normal p-2 block w-full text-left">
                Income / Expense
              </button>
              <button
                  onClick={() => {
                    setShowMoreMenu(false);
                    dispatch(setPolyline(false));
                    dispatch(updateVehicleRemainderPopup(true));
                  }}
                  className="text-zinc-500 text-base font-normal p-2 block w-full text-left">
                Remainders
              </button>
            </div>
        )}
      </div>
  );
}
