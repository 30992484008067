import {AiOutlineDashboard} from 'react-icons/ai';
import {FaKey, FaPlug} from 'react-icons/fa';
import {GiHorizonRoad} from 'react-icons/gi';
import {PiEngineFill, PiFanDuotone} from 'react-icons/pi';
import {BsFuelPumpFill} from 'react-icons/bs';
import {BiSolidTimeFive, BiSolidTimer} from 'react-icons/bi';

import CountDownTime from './CountDownTime';
import {getImageUrl, get} from '../helpers';
import GpsBatteryStatus from './GpsBatteryStatus';
import GpsSignalStrengthStatus from './GpsSignalStrengthStatus';
import GpsExternalBatteryStatus from './GpsExternalBatteryStatus';
import SecondaryEngineRanHours from './SecondaryEngineRanHours';

export default function DeviceCard(
    {
      device,
      selected = false,
      minimal = false,
      onSelect = () => {
      },
    },
) {
  const tripSummary = get(device, 'trip_summary', {});
  const distanceTravelled = get(tripSummary, 'distance_traveled', 0);
  const secondaryEngineRanHours = get(tripSummary, 'engine_hours', 0);

  const terminal = get(device, 'terminal', {});
  const type = get(device, 'type', 'bike');
  const name = get(device, 'name', '');

  const status = get(terminal, 'status', 'parked');
  const ignitionStatus = get(terminal, 'ignition', false);
  const chargingStatus = get(terminal, 'plugged', false);
  const odometer = get(terminal, 'odometer', 0);
  const lastRequestTime = get(terminal, 'last_request_at', null);
  const lastLocation = get(terminal, 'last_location', {});
  const parkedTime = get(terminal, 'ended_at', {});
  const engineType = get(terminal, 'engine_type', 'none');
  const engineRunning = get(terminal, 'engine_running', false);
  const totalEngineHours = get(terminal, 'engine_hours', 0);
  const engineLastOnTime = get(terminal, 'engine_on_at', null);

  const lastLocationDate = get(lastLocation, 'timestamp', null);
  const address = get(lastLocation, 'address', null);
  const speed = get(lastLocation, 'speed', 0);

  let movingSpeed = 0;
  if (status === 'moving') {
    if (speed) {
      movingSpeed = speed;
    } else if (lastLocation) {
      movingSpeed = Number(lastLocation.speed);
    } else {
      movingSpeed = 0;
    }
  }

  return (
      <div
          className={`${status === 'offline' ?
              'bg-red-100':
              'bg-white'} rounded-lg shadow border ${status === 'offline' ?
              'border-red-600':
              selected ?
                  'border-blue-950':
                  'border-neutral-200'} w-full mb-2 cursor-pointer`}
          onClick={onSelect}
      >
        <div className="p-3">
          <div className="flex items-center">
            <div
                className="flex items-center justify-center mr-2 flex-col">
              <div>
                <img alt="device Icon"
                     src={getImageUrl(type, status, '', 'icon')} width={50}
                     height={50}/>
              </div>
              {status !== 'offline' && !minimal && (<>
                <div className="mt-1 flex justify-between w-full">
                  <span>
                    <GpsBatteryStatus
                        status={get(device, 'terminal.battery_level', 'high')}
                    />
                  </span>
                  <span>
                    <GpsSignalStrengthStatus
                        status={get(device, 'terminal.signal_strength',
                            'no_signal')
                        }/>
                  </span>
                </div>
                <div className="w-full">
                  <GpsExternalBatteryStatus
                      status={get(device, 'terminal.external_voltage', 0)}
                  />
                </div>
              </>)}
            </div>
            <div className="relative flex-1">
              <div className="flex items-center justify-between mb-2 flex-wrap">
                <div className={`text-black text-sm font-bold`}>
                  {name}
                </div>
                {lastRequestTime &&
                    <CountDownTime dateTime={lastRequestTime} status={status}/>}
              </div>
              {address && (
                  <div className="text-zinc-500 text-xs font-normal mb-2">
                    {address}
                  </div>
              )}
              {parkedTime && status === 'parked' && (
                  <div className="text-black text-xs font-bold">
                    Parked since {new Date(parkedTime).toLocaleString()}
                  </div>
              )}
              {lastLocationDate && status === 'idle' && (
                  <div className="text-black text-xs font-bold">
                    Idle since {new Date(lastLocationDate).toLocaleString()}
                  </div>
              )}
              {status === 'moving' && movingSpeed && (
                  <div className="text-black text-xs font-bold">
                    Moving at {movingSpeed.toFixed(1)} Km/Hr speed
                  </div>
              )}
              {status === 'offline' && (
                  <div className="text-black text-xs font-bold">
                    Offline since {new Date(lastRequestTime).toLocaleString()}
                  </div>
              )}
            </div>
          </div>
        </div>
        {!minimal && (
            <div className={`${status === 'offline' ?
                'bg-red-50':
                'bg-zinc-100'} rounded-bl-lg rounded-br-lg py-1 px-3`}>
              <div className="flex justify-between">
                <div className="flex items-center flex-1">
                  <span className="mr-1">
                    <FaKey color={status === 'offline' ?
                        '#FF0004':
                        ignitionStatus ? '#0EB600':'#7F7F7F'} size={24}/>
                  </span>
                  <span className={`text-xs ${ignitionStatus ?
                      'font-bold text-green-600 ':
                      'font-normal text-zinc-500'}`}>
                    {ignitionStatus ? 'ON':'OFF'}
                  </span>
                </div>
                <div className="flex items-center flex-1">
                  <span className="mr-1">
                    <FaPlug color={status === 'offline' ?
                        '#FF0004':chargingStatus ? '#0EB600':'#7F7F7F'}
                            size={24}
                    />
                  </span>
                  <span
                      className={`text-xs ${chargingStatus ?
                          'font-bold text-green-600 ':
                          'font-normal text-zinc-500'}`}>
                    {chargingStatus ? 'ON':'OFF'}
                  </span>
                </div>
                <div className="flex items-center flex-1">
                  <span className="mr-1">
                    <AiOutlineDashboard color="#7F7F7F" size={24}/>
                  </span>
                  <span className="text-zinc-500 text-xs font-normal">
                    {odometer.toFixed(1)}km
                  </span>
                </div>
                <div className="flex items-center flex-1 justify-end">
                  <span className="mr-1">
                    <GiHorizonRoad color="#7F7F7F" size={24}/>
                  </span>
                  <span className="text-zinc-500 text-xs font-normal">
                    {distanceTravelled.toFixed(1)}km
                  </span>
                </div>
              </div>
              {engineType !== 'none' && (
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center flex-1">
                      <span className="mr-1">
                        <BsFuelPumpFill
                            color={status === 'offline' ? '#FF0004':'#7F7F7F'}
                            size={24}/>
                      </span>
                      <span className="text-xs font-normal text-zinc-500"
                      >
                        N/A
                      </span>
                    </div>
                    <div className="flex items-center flex-1">
                      {engineType === 'ac' ? (
                          <span className="mr-1">
                            <PiFanDuotone color={status === 'offline' ?
                                '#FF0004':
                                engineRunning ? '#0EB600':'#7F7F7F'}
                                          size={24}/>
                          </span>
                      ):(
                          <span className="mr-1">
                            <PiEngineFill color={status === 'offline' ?
                                '#FF0004':
                                engineRunning ? '#0EB600':'#7F7F7F'}
                                          size={24}/>
                          </span>
                      )}
                      <span className={`text-xs ${engineRunning ?
                          'font-bold text-green-600 ':
                          'font-normal text-zinc-500'}`}
                      >
                        {engineRunning ? 'ON':'OFF'}
                      </span>
                    </div>
                    <div className="flex items-center flex-1">
                      <span className="mr-1">
                        <BiSolidTimeFive color="#7F7F7F" size={24}/>
                      </span>
                      <span className="text-zinc-500 text-xs font-normal">
                        <SecondaryEngineRanHours
                            totalRanHours={totalEngineHours}
                            lastOnTime={engineLastOnTime}/>
                      </span>
                    </div>
                    <div className="flex items-center flex-1 justify-end">
                      <span className="mr-1">
                        <BiSolidTimer color="#7F7F7F" size={24}/>
                      </span>
                      <span className="text-zinc-500 text-xs font-normal">
                        <SecondaryEngineRanHours
                            totalRanHours={secondaryEngineRanHours}
                            lastOnTime={engineLastOnTime}/>
                      </span>
                    </div>
                  </div>
              )}
            </div>
        )}
      </div>
  );
}
