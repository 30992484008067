import {useEffect, useState} from 'react';
import {timeAgo} from '../helpers';

export default function CountDownTime({dateTime, status}) {
  const [formattedDate, setFormattedDate] = useState('');
  useEffect(() => {
    setFormattedDate(timeAgo(dateTime));
    // Update every second
    const intervalId = setInterval(() => {
      setFormattedDate(timeAgo(dateTime));
    }, 1000);

    // Return a function to clear the interval when needed
    return () => clearInterval(intervalId);
  }, [dateTime]);

  return (
      <div
          className={`${status === 'offline' ?
              'bg-red-50':
              'bg-zinc-100'} rounded-lg text-blue-950 text-xs font-bold p-1`}>
        {formattedDate} {formattedDate === 'Just now' ? '':'ago'}
      </div>
  );
}
