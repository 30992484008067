import React from 'react';
import {useSelector} from 'react-redux';
import {get} from '../helpers';

export default function MobileSingularDetails() {
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const allDevices = useSelector((state) => state.device.list);
  const deviceDetails = allDevices.find((item) => item.imei === selectedDevice);
  if (!deviceDetails) {
    return null;
  }

  const tripSummary = get(deviceDetails,'trip_summary', {});
  const averageSpeed = get(tripSummary,'average_speed', 0);
  const maxSpeed = get(tripSummary,'max_speed', 0);
  const overSpeedKms = get(tripSummary,'over_speed_distance', 0);

  return (
      <div
          className="flex md:justify-center justify-evenly p-2 absolute top-0 w-full z-[100]">
        <div className="mx-1">
          <div
              className="opacity-80 bg-blue-950 rounded-3xl shadow border border-neutral-200 px-5 py-2 text-center text-white text-xs font-normal">
            Avg Speed
          </div>
          <div className="flex flex-col justify-center items-center mt-1">
            <div className="tooltip-triangle"></div>
            <div
                className=" bg-white rounded shadow text-zinc-500 text-xs font-bold text-center py-2 px-3">
              {averageSpeed.toFixed(1)} Km/Hr
            </div>
          </div>
        </div>
        <div className="mx-1">
          <div
              className="opacity-80 bg-white rounded-3xl shadow border border-neutral-200 px-5 py-2 opacity-70 text-center text-zinc-800 text-xs font-normal tracking-tight">
            Max Speed
          </div>
          <div className="flex flex-col justify-center items-center mt-1">
            <div className="tooltip-triangle"></div>
            <div
                className=" bg-white rounded shadow text-zinc-500 text-xs font-bold text-center py-2 px-3">
              {maxSpeed.toFixed(1)} Km/Hr
            </div>
          </div>
        </div>
        <div className="mx-1">
          <div
              className="bg-red-600 rounded-3xl shadow border border-neutral-200 px-5 py-2 text-center text-white text-xs font-normal tracking-tight">
            Over Speed
          </div>
          <div className="flex flex-col justify-center items-center mt-1">
            <div className="tooltip-triangle"></div>
            <div
                className=" bg-white rounded shadow text-zinc-500 text-xs font-bold text-center py-2 px-3">
              {overSpeedKms.toFixed(1)} Kms
            </div>
          </div>
        </div>
      </div>
  );
}
