import {useDispatch} from 'react-redux';
import {useState} from 'react';

import Popup from './Popup';
import {
  updateLoading,
  updateLocationSharingPopup,
} from '../redux/slices/generalSlice';
import axios from '../axios';
import {get} from '../helpers';
import {toast} from 'react-toastify';

export default function LocationSharingPopup({selectedDevice, updateSharing, show}) {
  const [shareType, setShareType] = useState('current');
  const [shareExpireDays, setShareExpiryDays] = useState(365);
  const dispatch = useDispatch();

  const closeShareLocationPopup = () => {
    dispatch(updateLocationSharingPopup(false));
  };

  const saveShareLocation = () => {
    if (shareExpireDays <= 0 && shareType === 'live') {
      return;
    }
    dispatch(updateLoading(true));
    axios.post(`/user/vehicle/sharing/create/${selectedDevice}`, {
      shareType: shareType,
      expireDays: shareExpireDays,
    })
        .then((response) => {
          if (get(response, 'success')) {
            updateSharing(get(response, 'location'));
            toast.success("Vehicle location sharing link created successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
          dispatch(updateLoading(false));
          dispatch(updateLocationSharingPopup(false));
        })
        .catch(() => {
          dispatch(updateLoading(false));
          toast.error("Vehicle location sharing failed.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  };

  return (
      <Popup title="Share Location" onClose={closeShareLocationPopup} show={show}>
        <div className="grid grid-cols-2 gap-2 my-5">
          <button
              onClick={() => {
                setShareType('current');
              }}
              className={`p-2 opacity-80 ${shareType === 'current' ?
                  'bg-blue-950 border-blue-950 text-white':
                  'border-neutral-200 bg-white text-neutral-400'} rounded-3xl shadow border text-sm font-normal tracking-tight text-center cursor-pointer`}
          >
            Current Location
          </button>
          <button
              onClick={() => {
                setShareType('live');
              }}
              className={`p-2 opacity-80 ${shareType === 'live' ?
                  'bg-blue-950 border-blue-950 text-white':
                  'border-neutral-200 bg-white text-neutral-400'} rounded-3xl shadow border text-sm font-normal tracking-tight text-center cursor-pointer`}>
            Live Location
          </button>
        </div>
        {shareType === 'live' && (
            <div className="flex my-5 items-center">
              <p className="text-zinc-500">Share for </p>
              <input
                  className="mx-2 p-1 w-24 border border-neutral-200 rounded"
                  type="number"
                  onChange={(e) => {
                    setShareExpiryDays(e.target.value);
                  }}
                  value={shareExpireDays}
              />
              <p className="text-zinc-500"> days</p>
            </div>
        )}
        <div className="mt-5 mb-2 text-center">
          <button
              className="bg-blue-950 rounded-2xl text-center text-white text-base font-normal py-2 px-5 mx-2 font-bold"
              onClick={saveShareLocation}
          >
            Share Now
          </button>
        </div>
      </Popup>
  );
}
