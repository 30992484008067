export const categories = [
  {
    'name': 'ex_fuel',
    'label': 'Fuel',
    'description': 'Expenses related to purchasing fuel for the vehicle.',
  },
  {
    'name': 'ex_maintenance',
    'label': 'Maintenance',
    'description': 'Costs associated with vehicle maintenance and repairs.',
  },
  {
    'name': 'ex_insurance',
    'label': 'Insurance',
    'description': 'Payments made for vehicle insurance coverage.',
  },
  {
    'name': 'ex_depreciation',
    'label': 'Depreciation',
    'description': 'Accounting for the decrease in a vehicle\'s value over time.',
  },
  {
    'name': 'ex_loan_payments',
    'label': 'Loan payments',
    'description': 'If the vehicle was purchased with a loan, the monthly loan payments.',
  },
  {
    'name': 'ex_registration_and_taxes',
    'label': 'Registration and taxes',
    'description': 'Fees paid for vehicle registration and taxes.',
  },
  {
    'name': 'ex_tires',
    'label': 'Tires',
    'description': 'Costs for purchasing and maintaining tires.',
  },
  {
    'name': 'ex_parking',
    'label': 'Parking',
    'description': ' Expenses for parking fees and permits.',
  },
  {
    'name': 'ex_licensing',
    'label': 'Licensing',
    'description': 'Costs associated with driver\'s license renewal.',
  },
  {
    'name': 'ex_toll_charges',
    'label': 'Toll charges',
    'description': 'Payments for using toll roads or bridges.',
  },
  {
    'name': 'ex_cleaning_and_detailing',
    'label': 'Cleaning and detailing',
    'description': 'Expenses for vehicle cleaning and detailing services.',
  },
  {
    'name': 'ex_accessories',
    'label': 'Accessories',
    'description': 'Costs for adding accessories or modifications to the vehicle.',
  },
  {
    'name': 'ex_lease_payments',
    'label': 'Lease payments',
    'description': 'Monthly payments for a leased vehicle.',
  },
  {
    'name': 'ex_roadside_assistance',
    'label': 'Roadside assistance',
    'description': 'Subscription or one-time payments for roadside assistance services.',
  },
  {
    'name': 'ex_fines_and_tickets',
    'label': 'Fines and tickets',
    'description': 'Penalties for traffic violations or parking tickets.',
  },
  {
    'name': 'ex_emissions_testing',
    'label': 'Emissions testing',
    'description': 'Fees for vehicle emissions testing and compliance.',
  },
  {
    'name': 'ex_vehicle_inspection',
    'label': 'Vehicle inspection',
    'description': 'Costs related to vehicle safety and emissions inspections.',
  },
  {
    'name': 'ex_garage_rent',
    'label': 'Garage rent',
    'description': 'Rental fees for a garage or storage space.',
  },
  {
    'name': 'ex_vehicle_wash',
    'label': 'Vehicle wash',
    'description': 'Expenses for vehicle washing and cleaning.',
  },
  {
    'name': 'ex_electric_charging',
    'label': 'Electric charging',
    'description': 'Costs for charging electric or hybrid vehicles.',
  },

  {
    'name': 'in_transportation_services',
    'label': 'Transportation services',
    'description': 'Income generated from providing transportation services.',
  },
  {
    'name': 'in_lease_income',
    'label': 'Rental income',
    'description': 'Income generated from renting out the vehicle to others.',
  },
  {
    'name': 'in_courier_services',
    'label': 'Courier Services',
    'description': 'Income from courier or package delivery services.',
  },
  {
    'name': 'in_tour_services',
    'label': 'Tour services',
    'description': 'Revenue from offering guided tours using the vehicle.',
  },
  {
    'name': 'in_corporate_transport',
    'label': 'Corporate transport',
    'description': 'Income from providing corporate transportation solutions.',
  },
];

export const remainderCategories = [
  {
    'name': 'fitness_certificate',
    'label': 'Fitness certificate',
    'description': 'FC.',
  },
  {
    'name': 'oil_change',
    'label': 'Oil change',
    'description': 'Regular oil changes ensure that the engine runs smoothly and extends the life of the vehicle.',
  },
  {
    'name': 'tire_rotation',
    'label': 'Tire rotation',
    'description': 'Rotating tires helps maintain even tread wear and improves handling and traction.',
  },
  {
    'name': 'brake_inspection',
    'label': 'Brake inspection',
    'description': 'Regular brake inspections ensure that your vehicle\'s brakes are in good working condition.',
  },
  {
    'name': 'registration_renewal',
    'label': 'Registration renewal',
    'description': 'Keeping your vehicle\'s registration up to date avoids legal issues and fines.',
  },
  {
    'name': 'insurance_renewal',
    'label': 'Insurance renewal',
    'description': 'Timely insurance renewal ensures coverage in case of accidents or damage.',
  },
  {
    'name': 'scheduled_maintenance',
    'label': 'Scheduled maintenance',
    'description': ' Routine maintenance checks, like air filter replacement or spark plug changes, can prevent breakdowns.',
  },
  {
    'name': 'tire_pressure',
    'label': 'Tire pressure',
    'description': 'Maintaining proper tire pressure ensures safety, better fuel efficiency, and tire longevity.',
  },
  {
    'name': 'battery_check',
    'label': 'Battery check',
    'description': 'Regular battery checks prevent unexpected battery failures.',
  },
  {
    'name': 'safety_inspection',
    'label': 'Safety inspection',
    'description': 'Periodic safety inspections ensure that all safety features of the vehicle are functional.',
  },
  {
    'name': 'emission_test',
    'label': 'Emission test',
    'description': 'Compliance with emission standards is essential to avoid penalties and reduce environmental impact.',
  },
  {
    'name': 'air_conditioning_service',
    'label': 'Air conditioning service',
    'description': 'Regular AC service ensures optimal cooling during hot weather.',
  },
  {
    'name': 'windshield_wipers',
    'label': 'Windshield wipers',
    'description': 'Replacing worn-out wipers improves visibility during rain or snow.',
  },
  {
    'name': 'mileage_records',
    'label': 'Mileage records',
    'description': 'Keeping track of your vehicle\'s mileage is essential for service scheduling.',
  },
  {
    'name': 'roadside_assistance_renewal',
    'label': 'Roadside assistance renewal',
    'description': 'Ensuring that your roadside assistance plan is up to date provides peace of mind in case of emergencies.',
  },
  {
    'name': 'cleaning_and_wash',
    'label': 'Cleaning and wash',
    'description': 'Regular cleaning and washing maintain the vehicle\'s appearance and prevent corrosion.',
  },
  {
    'name': 'alignment_check',
    'label': 'Alignment check',
    'description': 'Periodic alignment checks ensure proper handling and tire wear.',
  },
  {
    'name': 'expiry_date_of_driving_license',
    'label': 'Expiry date of driving license',
    'description': 'Keeping an eye on your driving license\'s expiry date helps you avoid legal issues.',
  },
  {
    'name': 'spare_tire_inspection',
    'label': 'Spare tire inspection',
    'description': 'Ensuring that your spare tire is in good condition is important for emergencies.',
  },
  {
    'name': 'emergency_kit',
    'label': 'Emergency kit',
    'description': 'Checking and updating your vehicle\'s emergency kit can be life-saving during breakdowns.',
  },
  {'name': 'other', 'label': 'Other', 'description': 'Other'},
];
